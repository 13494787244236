import { createStore } from 'vuex';
import VuexORM from '@vuex-orm/core';
import VuexORMValidate from 'Core/utils/VuexORMValidate';
import Router from '../router';

/**
 * Devuelve un Router con todas las rutas de los módulos
 *
 * @param {Array} modules - Listado de módulos
 * @return {Router}
 */
const getRouter = modules => {
  const routes = [];

  modules.forEach(module => {
    if (module.routes) {
      module.routes.forEach(route => {
        //
        // Si son rutas hijas, busco al padre y las añado dentro
        //
        if (route.parent) {
          const parent = routes.findIndex(item => item.path === route.parent);

          if (parent >= 0) {
            //
            // Si el padre no tiene la propiedad "children" la creo
            //
            if (!routes[parent].children) routes[parent].children = [];

            routes[parent].children.push(route);
            return;
          }
        }

        //
        // Si la ruta no tiene padre o no lo encuentro la añado al root
        //
        routes.push(route);
      });
    }
  });

  //
  // Añado todas las rutas al router
  //
  // Router.addRoutes(routes);
  routes.forEach( route => {
    Router.addRoute(route);
  })

  Router.addRoute({path:'/:catchAll(.*)',redirect: '/'});

  return Router;
};

/**
 * Devuelve un Store de Vuex con todos los modelos
 *
 * @param {Array} modules - Listado de módulos
 * @return {Store}
 */
const getStore = modules => {
  //
  // Creo una nueva base de datos
  //
  VuexORM.use(VuexORMValidate);
  const database = new VuexORM.Database();

  //
  // Registro cada modelo en la base de datos
  //
  const modulesStore = {state: {}, mutations: {}, actions: {}, getters: {}};
  modules.forEach(module => {
    if(module.store) {
      modulesStore.state = { ...modulesStore.state, ...module.store.state};
      modulesStore.mutations = { ...modulesStore.mutations, ...module.store.mutations};
      modulesStore.getters = { ...modulesStore.getters, ...module.store.getters};
      modulesStore.actions = { ...modulesStore.actions, ...module.store.actions};
    }

    if (module.models) {
      module.models.forEach(model => {
        database.register(model);
      });
    }
  });

  // app.use(Vuex);

  //
  // Crea un nuevo Store con todos los modelos y lo devuelvo
  //
  const store = createStore({
    state: modulesStore.state,
    mutations: modulesStore.mutations,
    actions: modulesStore.actions,
    getters: modulesStore.getters,
    plugins: [VuexORM.install(database)],
  });

  return store
};

/**
 * Devuelve un objeto con la configuración de todos los módulos
 *
 * @param {Array} modules - Listado de módulos
 * @return {Object}
 */
const getConstants = modules => {
  let constants = {};

  //
  // Obtengo las constantes de cada módulo y las junto
  //
  modules.forEach(module => {
    if (module.constants) {
      constants = { ...constants, ...module.constants };
    }
  });

  return constants;
};

export {
  getRouter,
  getStore,
  getConstants,
};
