import store from 'Core/store/store';
import { createRouter, createWebHashHistory } from 'vue-router';
import StorageService from 'Core/services/storage.service';
import CONSTANTS from 'Core/constant';
import Toast from 'Core/components/Toast';
import popup from 'Core/components/PopupGlobal'
// Importo todo el modelo para limpiarlo si no estoy logado
import User from 'Auth/models/User';
import Installation from 'Units/models/Installation.model';
import Webserver from 'Units/models/Webserver.model';
import { Device } from 'Units/models/DeviceHierarchy';
import Group from 'Units/models/Group.model';
import Schedule from 'Units/models/Schedule.model';
import ScheduleConf from 'Units/models/ScheduleConf.model';
import i18n from 'Core/services/language.service';
import { getParamURI } from './utils/utils';


const baseURL = CONSTANTS.CONNECT.APP.AZCLOUD_API_URL;

/**
 * Configuración del Router
 *
 * MODE
 * Utilizamos el HTML5 history API para evitar tener rutas con hashes
 * Esta opción requiere modificar algunos parámetros en el servidor:
 * https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
 *
 * SCROLLBEHAVIOR
 * Simula el comportamiento nativo cuando navegamos a nueva ruta y volvemos a la anterior
 * con el botón de volver atrás, y la posición del scroll de la página se mantiene donde
 * nos habíamos quedado.
 */
// const router = new VueRouter({

//   mode: 'hash',
//   base: baseURL,
//   routes: [],
//   scrollBehavior() {
//     return { x: 0, y: 0 };
//   },
// });

const router = createRouter({
  history: createWebHashHistory(''),
  base: baseURL,
  routes: [],
})

/**
 * Configuración previa a navegar a una nueva página
 */
router.beforeEach(async (routeTo, routeFrom, next) => {
  // Cuando cargamos cualquier nueva vista activamos el modo carga (loading)
  // salvo que controlemos en la ruta que no queramos carga

  if(routeTo.params.loading !== false) {
    Toast.loading({
      closeOnClick: false
    });
  }

  store.dispatch('setRenderStatus', false);

  // Si la aplicación no requiere autenticación no compruebo nada
  if (!CONSTANTS.SETTINGS.HAS_AUTHENTICATION) {
    return next();
  }

  // Obtenemos la URL por si venimos de algún proceso de asociación externo
  const pageURL = window.location.href.split('#')[0];

  const state = getParamURI(pageURL, 'state');

  try {
    const decode= JSON.parse(window.atob(state));

    if(decode.path !== undefined && routeTo.name !== decode.path) {
      return next({name: decode.path, params: decode, query: {url: pageURL} })
    }
  } catch(error) {
    // console.log("Base64 no válido. Seguimos ejecución");
  }



  const isPrivate = routeTo.matched.some(route => route.meta.private);
  const onlyWhenLoggedOut = routeTo.matched.some(record => record.meta.onlyWhenLoggedOut);
  const token = StorageService.getItem('access_token')
  const isLogged = token || store.getters.getIsDemo;

  // Si no estoy logado limpio el modelo de datos
  if(!isLogged){
    User.deleteAll();
    Device.deleteAll();
    Installation.deleteAll();
    Group.deleteAll();
    Webserver.deleteAll();
    Schedule.deleteAll()
    ScheduleConf.deleteAll();
  }

  // Si la ruta es privada y el usuario no está logueado lo redirigimos al login
  // y almacenamos la ruta para redireccionar al usuario después de hacer login
  if (isPrivate && !isLogged) {
    Toast.clear();

    return next({
      name: 'login',
      query: { reason: 'notPermission', redirect: routeTo.name },
    });
  }

  // No permitimos al usuario visitar ciertas páginas mientras está logueado
  if (isLogged && onlyWhenLoggedOut) {
    Toast.clear();
    // Redirigimos a la raiz
    return next('/');
  }

  return next();
});

router.afterEach(() => {
  //
  // Después de entrar en cada vista limpiamos el modo "loading"
  // Para asegurarme que se limpia al salir de la ruta
  //
  // console.log(`Router log: After ${from.name} -- to --> ${to.name}`); //
  // if(parseBoolean(to.query.loading) !== false )
  // App.setLoading(false);
  store.dispatch('setRenderStatus', true);
  Toast.clear();
})
export default router;
