import Webserver from 'Units/models/Webserver.model';
import User from 'Auth/models/User';
import { Device, Zone, System, CCP } from 'Units/models/DeviceHierarchy';

import Toast from 'Core/components/Toast';
import Dialog from 'Core/components/Dialog';

import BleUtils from 'Units/utils/ble.utils';
import { showPopupPin, checkDisabledInternet } from 'Core/utils/popup.utils'
import localUserData from 'Auth/services/localUserData.service';
import BluetoothService from 'Core/services/bluetooth.service';
import store from 'Core/store/store';
import { V1lowerThanV2, compareVersions } from 'Core/utils/utils';

const wizardRoutes = {

    path: 'wizard/:macBLE',
    name: 'wizard',
    component: () => import('../pages/Wizard'),
    props: true,
    meta: {
      isBle: true,
      fromWizardConfig: true,
    },
    redirect: {
      name: 'wizardMain'
    },
    beforeEnter: async (to, from, next) => {
      try {
        if (store.getters.getIsDemo) {
          return next()
        }
        const isUserAllow = store.getters.getIsUserAllow

        let ws = Webserver.query().where('macBLE', to.params.macBLE).first();

        if(from.params.webserverID === undefined) {
          //
          // Registro cambios en Bluetooth
          //
          await BleUtils.getDeviceBleModel(to.params.macBLE).catch(error => {
            BluetoothService.disconnectDevice(to.params.macBLE);
            console.error(error);
            Toast.clear();
            Dialog.error(error);
            return next({name: from.name})
          })

          ws = Webserver.query().where('macBLE', to.params.macBLE).first();
          to.params.webserverID = ws.id;
        }

        if ( (isUserAllow || ws?.isUserAllow) === false ) {

          try {
            Toast.clear()

            const code = await showPopupPin(ws.mac, false, false, '')
            // Inicializamos el servicio y preparamos el usuario para
            // almacenar las credenciales en local storage
            localUserData.init();
            const user = User.query().first();
            localUserData.checkUser(user.email);
            localUserData.setPincode(user.email, ws.mac, code);

            // Guardamos el pin en el modelo
            await ws.setParam('pin', code);

            // Hacemos que el usuario esté permitido en VUEX
            await ws.setParam('isUserAllow', true);
            store.dispatch('setIsUserAllowStatus', true);

            return next()

          } catch(err) {
            Toast.clear();
            return next({ name: from.name, params: from.params, query: from.query })
          }
        }

        return next();
      }
      catch ( error ) {
        BluetoothService.disconnectDevice(to.params.macBLE);
        console.error(error);
        Toast.clear();
        Dialog.error(error);

        return next({name: from.name})
      }
    },
    children: [
      {
        path: 'warning',
        name: 'wizardMain',
        component: () => import('Units/pages/Wizard/WizardMain'),
        props: true

      },
      {
        path: 'scheme',
        name: 'schemeDetected',
        component: () => import('Units/pages/Wizard/SchemeDetected'),
        props: true,
        beforeEnter: async (to, from, next) => {
          const macBLE = to.params.macBLE;
          const device = Webserver.query().where('macBLE', macBLE).first();
          // const user = User.query().first();


          try {
            if (store.getters.getIsDemo) {
              return next()
            }

            // Obtenemos la info del webserver
            await Webserver.getTopology(macBLE, device.id, true, {view: "info_extended"})

            return next();

          } catch( error) {
            console.log(error);
            Toast.clear();
            Dialog.error(error);

            return next({name: from.name});
          }
        },
      },
      {
        path: 'config/:webserverID',
        name: 'wizardConfigMain',
        component: () => import('Units/pages/Wizard/ConfigMain'),
        props: route => {
          return {
            ...route.params,
            refreshTopology: String(route.params.refreshTopology) === "true",
          }
        },
        beforeEnter: async (to, from, next) => {
          const macBLE = to.params.macBLE;
          const device = Webserver.query().where('macBLE', macBLE).first();
          const ccps = CCP.all();

          // Si es central y no tenemos ccps entonces dirigimos directamente a la vista de sistema (sólo habrá un sistema)
          if(device.isCentral && ccps.length === 0) {
            if(from.name === 'schemeDetected') {
              const system = System.query().first();

              return next({name: 'wizardConfigSystem', params: {...to.params, systemID: system.id, isCentral: true}})
            }
            return next({name: 'schemeDetected'});
          }


          // Si tenemos activo el FLAG de refrescar la topología en la vista system lo hacemos
          if(String(to?.params?.refreshTopology) === "true" && !store.getters.getIsDemo) {

            try {

              await Webserver.getTopology(macBLE, device.id, true, {view: "info_extended"});

              return next();

            } catch( error) {
              console.log(error);
              Toast.clear();
              Dialog.error(error);

              return next({name: from.name});
            }
          } else {
            return next()
          }
        }
      },
      {
        path: 'configWebserver/:webserverID',
        name: 'wizardConfigWebserver',
        component: () => import('Units/pages/Wizard/WizardConfigWebserver'),
        props: true,
        beforeEnter: async (to, from, next) => {
          const webserver = Webserver.find(to.params.webserverID);

          if (!webserver.hasIntegrations) {
            await Webserver.getWebserverServicesBLE(webserver.macBLE, webserver.id);
          }

          return next();
        }
      },
      {
        path: 'configCcp/:webserverID',
        name: 'wizardConfigCcp',
        component: () => import('Units/pages/Wizard/WizardConfigCcp'),
        props: true
      },
      {
        path: 'configSystem/:webserverID/sys/:systemID',
        name: 'wizardConfigSystem',
        component: () => import('Units/pages/Wizard/WizardConfigSystem'),
        props: route => {
          return {
            ...route.params,
            refreshTopology: String(route.params.refreshTopology) === "true",
          }
        },
        beforeEnter: async (to, from, next) => {
          // Si tenemos activo el FLAG de refrescar la topología en la vista system lo hacemos
          if(String(to?.params?.refreshTopology) === "true" && !store.getters.getIsDemo) {
            const macBLE = to.params.macBLE;
            const device = Webserver.query().where('macBLE', macBLE).first();

            try {
              // Limpiamos el modelo de zonas antes de refrescar la topología (para refrescar las zoans virtuales)
              Zone.deleteAll();
              await Webserver.getTopology(macBLE, device.id, true, {view: "info_extended"});

              return next();

            } catch( error) {
              console.log(error);
              Toast.clear();
              Dialog.error(error);

              return next({name: from.name});
            }
          } else {
            return next()
          }
        }
      },
      {
        path: 'configZone/:webserverID/sys/:systemID/zone/:zoneID',
        name: 'wizardConfigZone',
        component: () => import('Units/pages/Wizard/WizardConfigZone'),
        props: true,
      },
      {
        path: 'configAirQSensor/:webserverID/sys/:systemID/airQSensor/:airQSensorID',
        name: 'wizardConfigAirQSensor',
        component: () => import('Units/pages/Wizard/WizardConfigAirQSensor'),
        props: true,

      },
      {
        path: 'wizardEnding/:webserverID',
        name: 'wizardEnding',
        component: () => import('Units/pages/Wizard/WizardEnding'),
        props: true
      },
      {
        path: 'wizardWebserverConfigBLE',
        name: 'wizardWebserverConfigBLE',
        component: () => import('Units/pages/AirtoolsBLE/AirtoolsWebserverConfigBLE'),
        props: true,
        beforeEnter: async (to, from, next) => {

          try {
            Toast.loading({
              closeOnclick: false
            })
            const macBLE = to.params.macBLE;
            const device = Webserver.query().where('macBLE', macBLE).first();
            const user = User.query().first();

            if (from.meta.fromAddDevice) {
              to.meta.fromAddDevice = true
              // Si vengo de addDevices, es necesario poner fromWizardConfig en true, sino no se puede acceder a un airqsensor.
              to.meta.fromWizardConfig = true
            }
            // TODO => Comprobar si estas rutas son accesibles desde wizard para cambiar el name o eliminar el bloque de la condición
            if(from.name === 'searchWifisBLE' || from.name === 'searchDevice'){

              // Si vengo de universalModbus en addDevices que ya tengo los devices, no los vuelvo a pedir
              if (!(from.name === 'searchWifisBLE' && Boolean(from.query?.universalModbus))) {
                await Webserver.getWebserverStatusBLE(macBLE, device.id, device.type, device.ble_version, device.wsver, user.email);
              }

              if(BleUtils.compatibleIntegrations(device)) {
                await Webserver.getWebserverServicesBLE(macBLE, device.id);
              }
            }
            if(device?.type === 'aidoo'){
              return next({name: 'wizardConfigBLEAidoo', params: { macBLE, webserverID: device.id }, query: to.query });
            }

            Toast.clear()

            return next();

          } catch (error) {
            console.log(error);
            Toast.clear();
            Dialog.error(error);
            return next({name: from.name});
          }
        },
      },
      {
        path: 'wizardConfigBLESystem/:deviceID',
        name: 'wizardConfigBLESystem',
        component: () => import('Units/pages/Airtools/AirtoolsSystem'),
        props: true,
        beforeEnter: async (to, from, next) => {

          try {
            const system = Device.find(to.params.deviceID);

            // Comprobamos si el sistema tiene pinza asociada
           const clamp = Device.query()
                .where('type', 'CLAMP')
                .where('system_number', system.system_number).first();

            // Si tiene pinza (medidor de consumo), obtenemos sus datos en el modelo
            if(clamp !== null) {
              await Device.getBleConfig({deviceID: clamp.id});
            }
            await Device.getBleConfig({deviceID: to.params.deviceID});
            next();
          } catch( error ) {
            console.log(error);
            Toast.clear();
            Dialog.error(error);
            next({name: from.name});
          }
        },
        redirect: {
          name: 'wizardConfigBLESystemInfo'
        },
        children: [
          {
            path: 'wizardInfo',
            name: 'wizardConfigBLESystemInfo',
            component: () => import('Units/pages/Airtools/AirtoolsSystemInfo'),
            props: true,
          },
          {
            path: 'wizardSettings',
            name: 'wizardConfigBLESystemSettings',
            component: () => import('Units/pages/Airtools/AirtoolsSystemSettings'),
            props: true,
          }
        ]
      },
      {
        path: 'wizardConfigBLEDevice/:deviceID',
        name: 'wizardConfigBLEDevice',
        component: () => import('Units/pages/Airtools/AirtoolsDevice'),
        props: true,
        beforeEnter: async (to, from, next) => {
          const device = Device.find(to.params.deviceID);

          // Si no hay device (P.Ejem: al eliminar una zona virtual y actualizar el modelo) -> Redirigimos a vista Airtools Webserver
          if(device === null) {
            return next({name: 'wizardWebserverConfigBLE', params: to.params, query: to.query});
          }

          try {
            // Comprobamos si el sistema tiene salidas TRV asociada
            const outputs = Device.query()
                .where('type', 'OUTPUTS')
                .where('system_number', device.system_number).first();

            if(outputs !== null) {
              await Device.getBleConfig({deviceID: outputs.id});
            }

            await Device.getBleConfig({deviceID: to.params.deviceID});


            return next();
          } catch( error ) {
            console.log(error);
            Toast.clear();
            Dialog.error(error);
            return next({name: from.name});
          }
        },
        redirect: {
          name: 'wizardConfigBLEDeviceInfo'
        },
        children: [
          {
            path: 'wizardInfo',
            name: 'wizardConfigBLEDeviceInfo',
            component: () => import('Units/pages/Airtools/AirtoolsDeviceInfo'),
            props: true,
          },
          {
            path: 'wizardSettings',
            name: 'wizardConfigBLEDeviceSettings',
            component: () => import('Units/pages/Airtools/AirtoolsDeviceSettings'),
            props: true,
          }
        ]
      },
      {
        path: 'wizardAidoo',
        name: 'wizardConfigBLEAidoo',
        component: () => import('Units/pages/Airtools/Aidoo'),
        props: true,
        beforeEnter: async (to, from, next) => {
          try {
            if (!from.query?.universalModbus) {
              // Si el webserver es un Aidoo obtenemos diréctamente la información de los dispositivos
              const devices = Device.query().where('webserver_id',to.params.webserverID).get();
              // No podemos usar forEach si queremos usar await dentro del bucle !
              for(let i = 0; i < devices.length; i++){
                // eslint-disable-next-line no-await-in-loop
                await Device.getBleConfig({deviceID: devices[i].id});

                // Deshabilito el botón de Reset de fabrica para los Aidoo Pro
                if (devices[i].device_type === 'aidoo_it') {
                  const webserver = Webserver.find(to.params.webserverID);
                  if (V1lowerThanV2(webserver.wsver, '6.10') || compareVersions(webserver.wsver, '7.00') === 0) {
                    webserver.setParam('resetDisabled', true);
                  }
                }
              }
            }
            next();
          } catch( error ) {
            console.log(error);
            Toast.clear();
            Dialog.error(error);
            next({name: from.name});
          }
        },
        redirect: {
          name: 'wizardConfigBLEAidooInfo'
        },
        children: [
          {
            path: 'wizardInfo',
            name: 'wizardConfigBLEAidooInfo',
            component: () => import('Units/pages/Airtools/Aidoo/AidooInfo'),
            props: true,
          },
          {
            path: 'wizardSettings',
            name: 'wizardConfigBLEAidooSettings',
            component: () => import('Units/pages/Airtools/Aidoo/AidooSettings'),
            props: true,
          },
          {
            path: 'wizardBLEUniversalModbus',
            name: 'wizardBLEUniversalModbus',
            component: () => import('Units/pages/AddDevices/BleAssociation/UniversalModbus'),
            props: true,
          },
        ]
      },
      {
        path:'wizardConfigBLECCP/:deviceID',
        name: 'wizardConfigBLECCP',
        component: () => import('Units/pages/Airtools/AirtoolsCCPSettings'),
        props: true,
        beforeEnter: async (to, from, next) => {
          if (store.getters.getIsDemo) {
            next();
          } else {
            try {
              await Device.getBleConfig({deviceID: to.params.deviceID});
              next();
            } catch( error ) {
              console.log(error);
              Toast.clear();
              Dialog.error(error);
              next({name: from.name});
            }
          }
        },
      },
      {
        path: 'wizardConfigBLEIntegration',
        name: 'wizardConfigBLEIntegration',
        component: () => import('Units/pages/AirtoolsBLE/AirtoolsConfigBLEIntegration'),
        props: true,
      },
      {
        path: 'wizardConfigBLENetwork',
        name: 'wizardConfigBLENetwork',
        component: () => import('Units/pages/AirtoolsBLE/AirtoolsConfigBLENetwork'),
        props: true,
        beforeEnter: async (to, from, next) => {
          try {
            Toast.clear()

            await checkDisabledInternet(to.params.webserverID, to.params.macBLE, to.query.deviceName)

            return next()
          } catch(err) {
            Toast.clear();
            console.log(err)
            return false
          }
        }
      },
    ]
}

export default wizardRoutes;
