import { Model } from '@vuex-orm/core';
import UnitsService from 'Units/services/units.service';
import UnitsError from 'Units/services/errors.service';
import log from 'Core/services/log.service';
import { getAvailablesOption, getAvailableTempsRanges, getAvailableCommon } from 'Units/utils/getAvailable';
import { hasProp } from 'Core/utils/validate.utils';
import { getModeStringValue } from 'Units/utils/mode.utils';
import getSetpointParam from 'Units/utils/getSetpointParam';
import CONSTANTS from 'Units/constant';
import Installation from './Installation.model';
import { Zone, Clamp, ACS, CCP, VMC, Relay, Purifier, Dehumidifier, System, Device  } from './DeviceHierarchy';
import store from 'Core/store/store';

export default class Group extends Model {
  static entity = 'groups';

  /** *************************************************************
   * CAMPOS
   ************************************************************** */
  static fields() {
    return {
      id: this.attr(null),
      installation_id: this.attr(null),
      name: this.string(''),
      position: this.number(''),
      devices: this.hasMany(Device, 'group_id'),
      systems: this.hasMany(System, 'group_id'),
      zones: this.hasMany(Zone, 'group_id'),
      clamps: this.hasMany(Clamp, 'group_id'),
      acs: this.hasMany(ACS, 'group_id'),
      vmc: this.hasMany(VMC, 'group_id'),
      relays: this.hasMany(Relay, 'group_id'),
      purifiers: this.hasMany(Purifier, 'group_id'),
      ccp: this.hasMany(CCP, 'group_id'),
      dehumidifier: this.hasMany(Dehumidifier, 'group_id'),
      warnings: this.attr(undefined).nullable(),
      errors: this.attr(undefined).nullable(),
    };
  }

  /** *************************************************************
   * LIFECYCLE HOOKS
   ************************************************************** */

  static afterCreate(model) {
    //
    // Compruebo si he recibido los datos mínimos del usuario para que la aplicación funcione
    //
    const validate = hasProp(model, ['id', 'name']);

    if (validate.length) throw new UnitsError('invalidGroupData', `Faltan los datos "${validate.join()}" del grupo`);
  }

  /** *************************************************************
   * ACCIONES
   ************************************************************** */

  getAvailableModes(zones) {
    if (!zones) zones = Zone.query().where('group_id', this.id).withAll().get();
    //
    // Prepraro el array con todos los modos disponibles que van a filtrase para obtener los comnunes entre zonas
    //
    const defaultModes = [
      'auto',
      'cool',
      'heat',
      'fan',
      'dry',
      'stop',
      'emergencyHeat'
    ];
    const availableOptions = getAvailablesOption('mode_available', defaultModes, zones);
    //
    // Si no hay opciones disponibles devuelvo al menos unas por defecto
    //
    if (!availableOptions) return null; // Si no hay ninuna zona con modos disponibles
    if (!availableOptions.length) return ['cool', 'heat'];
    return availableOptions;
  }

  getAvailableRange(zones) {
    if (!zones) zones = Zone.query().where('group_id', this.id).withAll().get();

    const tempRanges = getAvailableTempsRanges(zones);

    return tempRanges;
  }

  get getDevicesForAvailableCommon() {
    const installation = Installation.find(this.installation_id);

    let devices = [];

    if(installation.isBasic) {
      devices = Device.query().where('group_id', this.id)
        .where((_record, query) => {
          query.where('device_type','az_zone')
          .orWhere('device_type','aidoo')
          .orWhere('device_type', CONSTANTS.DEVICE_TYPE.aidoo_it)
          .orWhere('device_type','aidoo_acs')
          .orWhere('device_type','az_acs')
          .orWhere('device_type','az_vmc')
        })
        .withAll().get();
    } else {
      devices = Device.query().where('group_id', this.id)
        .where((_record, query) => {
          query.where('device_type','az_system')
          .orWhere('device_type','az_zone')
          .orWhere('device_type','aidoo')
          .orWhere('device_type', CONSTANTS.DEVICE_TYPE.aidoo_it)
          .orWhere('device_type','aidoo_acs')
          .orWhere('device_type','az_acs')
          .orWhere('device_type','az_vmc')
        })
        .withAll().get();
    }

    return devices;
  }

  async getAvailableCommon() {
      try {
        const devices = this.getDevicesForAvailableCommon

        const availableCommon = await getAvailableCommon(devices);

        return availableCommon;
      } catch (err) {
        return err;
      }
  }

  /**
   * Actualiza un parámetro de todas las zonas de un grupo
   *
   * @param {String} action - La acción a realizar en el grupo
   * @param {String,Number} value - La información a modificar {param: value}
   */
  setGroupStatus = async ({ data, groupTabSelected }) => {
    // Realizo la petición al backend
    if(!store.getters.getIsDemo){
      await UnitsService.setGroupStatus({installationID: this.installation_id, groupID: this.id, data, groupTabSelected});
      log.info(`SetGroupStatus ${this.name}: ${JSON.stringify(data)}`);
      return true;
    }

    // Simulo la petición y actualizo todos los dispositivos del tab seleccionado.
    let devicesInTab = [];
    if(groupTabSelected) {
      devicesInTab = Device.query().where('group_id', this.id).where('device_semantic_type', CONSTANTS.SEMANTIC_DEVICE_GROUP_TYPE[groupTabSelected]).get();
    } else {
      devicesInTab = Device.query().where('group_id', this.id).where('device_semantic_type', CONSTANTS.SEMANTIC_DEVICE_GROUP_TYPE.climatization).get();
    }

    const devicesData = {};
    let mode;
    Object.keys(data.params).forEach( param => {
      if(param === 'opts') return;

      if(param === 'mode') {
        if(mode === undefined) {
          mode = getModeStringValue(data.params[param]);
        }

        devicesData[param] = mode;
      } else if( param === 'setpoint'){
        const setpointParam = getSetpointParam(mode);
        devicesData[setpointParam] = data.params[param];
      } else if( param === 'timer') {
        devicesData[param] = {action: {power: false}, value: data.params[param]};
      } else{
        devicesData[param] = data.params[param];
      }
    })

    devicesInTab.forEach(async device => {
      switch(device.type) {
        case CONSTANTS.DEVICE_TO_MODEL_TYPE.aidoo:
          await Zone.update({ where: device.id, data: devicesData, });
          break;
        case CONSTANTS.DEVICE_TO_MODEL_TYPE.az_acs:
          await ACS.update({ where: device.id, data: devicesData, });
          break;
        case CONSTANTS.DEVICE_TO_MODEL_TYPE.az_vmc:
          await VMC.update({ where: device.id, data: devicesData, });
          break;
        case CONSTANTS.DEVICE_TO_MODEL_TYPE.az_relay:
          await Relay.update({ where: device.id, data: devicesData, });
          break;
        default:
      }

    });

    log.info(`SetGroupStatus ${this.name}: ${JSON.stringify(data)}`);
    document.dispatchEvent(new CustomEvent('reloadAllZones', {}));

    return true;
  };

   /**
   * Actualiza la configuración de usuario de un grupo en Installation Settings
   *
   * @param {String} installationID - ID de la instalación a la que pertenece
   * @param {String} groupID - ID del grupo a actualizar
   * @param {String} param - Parámetro a actualizar
   * @param {String} value - Valor a actualizar
   * @throws {badParams} - Los datos enviados no son válidos
   * @throws {notAuthorized} - Usuario no autorizado a hacer cambios en el grupo
   * @throws {userNotIncluded} - El usuario que hace la petición no pertenece a la instalación
   * @throws {groupNotFound} - La id de grupo no partenece a la instalación
   */
  setGroupUserConf = async (param, value) => {

    await UnitsService.setGroupUserConf(this.installation_id, this.id, param, value);

    log.info(`SetGroupStatus ${this.name}: ${JSON.stringify({param, value})}`);

  }

  /**
   * Actualiza el modelo (sin enviar al servidor)
   *
   * @param {String} param
   * @param {*} value
   */
  updateParam = async (param, value) => {
    const groupID = this.id;
    const data = {};
    data[param] = value;

    console.log(`SetParam: ${param}, ${value}`);

    await Group.update({
      where: groupID,
      data
    });

    log.success(`Editado ${param}`);
  }


  /**
   * Actualiza el modelo tras guardar los datos en el servidor (backend)
   *
   * @param {String} param
   * @param {*} value
   */
  saveParam = async (param, value) => {
    const groupID = this.id;
    const installationID = this.installation_id;
    const data = {};

    data[param] = value;

    await UnitsService.editGroup(installationID, groupID, param, value);


    await Group.update({
      where: groupID,
      data
    });

    log.success(`SaveParam: ${param}, ${value}`);

  }

   /**
   * Indica la nueva posición de ordenación del grupo
   *
   * @param {number} position
   */
  static setPositionGroup = async (installationID, groupID, position) => {
    if(!store.getters.getIsDemo){
      await UnitsService.setPositionGroup(installationID, groupID, position);
    }

    log.info(`SetGroupPosition ${this.name} to position ${position}`);

  };
}
