import moment from 'moment-timezone';


const energyApi = {

  get24hMockObject (startDate, numSensors) {
    const energyApiMockObject = {
      sensors: []
    }

    for( let i = 0; i < numSensors; i++) {
      let date = moment(startDate);
      const firstHour = moment(startDate).startOf('day');

      const sensor = {
        total_cost: 0,
        total: 0,
        device_id: i.toString(),
        values: [],
        name: 'Sensor ' + (i+1),
        ws_alias: 'Airzone',
        stats: {
          energy: {
            min: { dt: firstHour.add(Math.round(Math.random() * 23), 'h').toISOString(), value: Math.random() * 100 },
            max: { dt: firstHour.add(Math.round(Math.random() * 23), 'h').toISOString(), value: Math.random() * 100 },
            avg: { dt: firstHour.add(Math.round(Math.random() * 23), 'h').toISOString(), value: Math.random() * 100 }
          },
          cost: {
            min: { dt: firstHour.add(Math.round(Math.random() * 23), 'h').toISOString(), value: Math.random() * 100 },
            max: { dt: firstHour.add(Math.round(Math.random() * 23), 'h').toISOString(), value: Math.random() * 100 },
            avg: { dt: firstHour.add(Math.round(Math.random() * 23), 'h').toISOString(), value: Math.random() * 100 }
          },
          distribution: [
            {
              period_id: "1",
              text: "Valle",
              color: "#1992D4",
              energy: Math.random() * 1000,
              cost: Math.random() * 300
            },
            {
              period_id: "2",
              text: "Llano",
              color: "#40C3F7",
              energy: Math.random() * 1000,
              cost: Math.random() * 300
            },
            {
              period_id: "3",
              text: "Punta",
              color: "#B3EBFF",
              energy: Math.random() * 1000,
              cost: Math.random() * 300
            },
          ]
        }
      }

      for(let j = 0; j < 24; j++) {
        // Un 10% de no obtener datos:
        const noData = Math.random() * 100 <= 10;

        const data = {
          dt: date.toISOString(),
          energy: noData ? null : Math.random() * 6,
          cost: noData ? null : Math.random() * 12,
          currency: { code: 'EUR', symbol: '€'}
        };

        date = date.add(1,'hour')

        if(!noData){
          sensor.total_cost += data.cost;
          sensor.total += data.energy;
          sensor.values.push(data);
        }
      }

      energyApiMockObject.sensors.push(sensor);
    }

    console.log("MOCK OBJECT", energyApiMockObject);
    return energyApiMockObject;
  },

  getWeekMockObject (startDate, numSensors, isSundayFirst = false) {
    const energyApiMockObject = {
      sensors: []
    }

    for( let i = 0; i < numSensors; i++) {
      let date = isSundayFirst? moment(startDate).subtract(1, 'days') : moment(startDate);
      const firstDay = moment(startDate).startOf('week');

      const sensor = {
        total_cost: 0,
        total: 0,
        device_id: i.toString(),
        values: [],
        ws_alias: 'Airzone',
        stats: {
          energy: {
            min: { dt: firstDay.add(Math.round(Math.random() * 6), 'd').toISOString(), value: Math.random() * 100 },
            max: { dt: firstDay.add(Math.round(Math.random() * 6), 'd').toISOString(), value: Math.random() * 100 },
            avg: { dt: firstDay.add(Math.round(Math.random() * 6), 'd').toISOString(), value: Math.random() * 100 }
          },
          cost: {
            min: { dt: firstDay.add(Math.round(Math.random() * 6), 'd').toISOString(), value: Math.random() * 100 },
            max: { dt: firstDay.add(Math.round(Math.random() * 6), 'd').toISOString(), value: Math.random() * 100 },
            avg: { dt: firstDay.add(Math.round(Math.random() * 6), 'd').toISOString(), value: Math.random() * 100 }
          },
          distribution: [
            {
              period_id: "1",
              key: "price.rates.low.title",
              color: "#1992D4",
              energy: Math.random() * 1000,
              cost: Math.random() * 300
            },
            {
              period_id: "2",
              key: "price.rates.mid.title",
              color: "#40C3F7",
              energy: Math.random() * 1000,
              cost: Math.random() * 300
            },
            {
              period_id: "3",
              key: "price.rates.high.title",
              color: "#B3EBFF",
              energy: Math.random() * 1000,
              cost: Math.random() * 300
            },
          ]
        }
      }

      for(let j = 0; j < 7; j++) {
        // Un 10% de no obtener datos:
        const noData = Math.random() * 100 <= 10;

        const data = {
          dt: date.toISOString(),
          energy: noData ? null : Math.random() * 50,
          cost: noData ? null : Math.random() * 50,
          currency: { code: 'EUR', symbol: '€'}
        };

        date = date.add(1,'day')

        if(!noData){
          sensor.total_cost += data.cost;
          sensor.total += data.energy;
          sensor.values.push(data);
        }
      }

      energyApiMockObject.sensors.push(sensor);
    }

    console.log("MOCK OBJECT", energyApiMockObject);
    return energyApiMockObject;
  },

  getMonthMockObject(startDate, numSensors) {
    const energyApiMockObject = {
      sensors: []
    }

    for( let i = 0; i < numSensors; i++) {
      let date = moment(startDate);
      const firstDay = moment(startDate).startOf('month');

      const sensor = {
        total_cost: 0,
        total: 0,
        device_id: i.toString(),
        values: [],
        ws_alias: 'Airzone',
        stats: {
          energy: {
            min: { dt: firstDay.add(Math.round(Math.random() * firstDay.daysInMonth() -1), 'd').toISOString(), value: Math.random() * 100 },
            max: { dt: firstDay.add(Math.round(Math.random() * firstDay.daysInMonth() -1), 'd').toISOString(), value: Math.random() * 100 },
            avg: { dt: firstDay.add(Math.round(Math.random() * firstDay.daysInMonth() -1), 'd').toISOString(), value: Math.random() * 100 }
          },
          cost: {
            min: { dt: firstDay.add(Math.round(Math.random() * firstDay.daysInMonth() -1), 'd').toISOString(), value: Math.random() * 100 },
            max: { dt: firstDay.add(Math.round(Math.random() * firstDay.daysInMonth() -1), 'd').toISOString(), value: Math.random() * 100 },
            avg: { dt: firstDay.add(Math.round(Math.random() * firstDay.daysInMonth() -1), 'd').toISOString(), value: Math.random() * 100 }
          },
          distribution: [
            {
              period_id: "1",
              text: "Valle",
              color: "#1992D4",
              energy: Math.random() * 1000,
              cost: Math.random() * 300
            },
            {
              period_id: "2",
              text: "Llano",
              color: "#40C3F7",
              energy: Math.random() * 1000,
              cost: Math.random() * 300
            },
            {
              period_id: "3",
              text: "Punta",
              color: "#B3EBFF",
              energy: Math.random() * 1000,
              cost: Math.random() * 300
            },
          ]
        }
      }

      for(let j = 0; j < date.daysInMonth(); j++) {
        // Un 2% de no obtener datos:
        const noData = Math.random() * 100 <= 2;
        const data = {
          dt: date.toISOString(),
          energy: noData ? null : Math.random() * 144,
          cost: noData ? null : Math.random() * 144
        };

        date = date.add(1,'day');

        if(!noData) {
          sensor.total_cost += data.cost;
          sensor.total += data.energy;
          sensor.values.push(data);
        }
      }

      energyApiMockObject.sensors.push(sensor);
    }

    console.log("MOCK OBJECT", energyApiMockObject);
    return energyApiMockObject;
  },

  getYearMockObject(startDate, numSensors) {
    const energyApiMockObject = {
      sensors: []
    }

    for( let i = 0; i < numSensors; i++) {
      let date = moment(startDate);
      const fisrtMonth = date.startOf('year');

      const sensor = {
        total_cost: 0,
        total: 0,
        device_id: i.toString(),
        values: [],
        ws_alias: 'Airzone',
        stats: {
          energy: {
            min: { dt: fisrtMonth.add(Math.round(Math.random() * 11), 'm').toISOString(), value: Math.random() * 100 },
            max: { dt: fisrtMonth.add(Math.round(Math.random() * 11), 'm').toISOString(), value: Math.random() * 100 },
            avg: { dt: fisrtMonth.add(Math.round(Math.random() * 11), 'm').toISOString(), value: Math.random() * 100 }
          },
          cost: {
            min: { dt: fisrtMonth.add(Math.round(Math.random() * 11), 'm').toISOString(), value: Math.random() * 100 },
            max: { dt: fisrtMonth.add(Math.round(Math.random() * 11), 'm').toISOString(), value: Math.random() * 100 },
            avg: { dt: fisrtMonth.add(Math.round(Math.random() * 11), 'm').toISOString(), value: Math.random() * 100 }
          },
          distribution: [
            {
              period_id: "1",
              text: "Valle",
              color: "#1992D4",
              energy: Math.random() * 1000,
              cost: Math.random() * 300
            },
            {
              period_id: "2",
              text: "Llano",
              color: "#40C3F7",
              energy: Math.random() * 1000,
              cost: Math.random() * 300
            },
            {
              period_id: "3",
              text: "Punta",
              color: "#B3EBFF",
              energy: Math.random() * 1000,
              cost: Math.random() * 300
            },
          ]
        }
      }

      for(let j = 0; j < 12; j++) {
        // Un 2% de no obtener datos:
        const noData = Math.random() * 100 <= 1;
        const data = {
          dt: date.toISOString(),
          energy: noData ? null : Math.random() * 1728,
          cost: noData ? null : Math.random() * 1728,
          currency: { code: 'EUR', symbol: '€'}
        };

        date = date.add(1,'month');

        if(!noData){
          sensor.total_cost += data.cost;
          sensor.total += data.energy;
          sensor.values.push(data);
        }
      }

      energyApiMockObject.sensors.push(sensor);
    }

    console.log("MOCK OBJECT", energyApiMockObject);
    return energyApiMockObject;
  }
}

export default energyApi;
