// import { getListLanguages } from 'Core/services/language.service';

const COLORS = {
  APP: {
    primary_100:'#01253C',
    primary_80:'#0B69A3',
    primary_60:'#1992D4',
    primary_40:'#40C3F7',
    primary_20:'#B3EBFF',
    green_100:'#00443A',
    green_80:'#01644F',
    green_60:'#079A75',
    green_40:'#2DCC9A',
    green_20:'#C6F7E5',
    red_100:'#3B0204',
    red_80:'#8A0404',
    red_60:'#D01D10',
    red_40:'#EF694E',
    red_20:'#FFC8BD',
    orange_100:'#4C2900',
    orange_80:'#994A00',
    orange_60:'#E27508',
    orange_40:'#F8BA68',
    orange_20:'#FFE6CC',
    dark_gray_100:'#202832',
    dark_gray_80:'#323F4B',
    dark_gray_60:'#52606D',
    dark_gray_40:'#7B8794',
    light_gray_100:'#9AA5B1',
    light_gray_80:'#BEC7D0',
    light_gray_60:'#D6DCE0',
    light_gray_40:'#EBF0F4',
    white:'#FFFFFF',
    black:'#000000',
    default: '#7b8793',
    default_gradient_start:'#9AA5B1',
    default_gradient_end:'#52606D',
    gray_gradient_start:'#EBF0F4',
    gray_gradient_end:'#BEC7D0',
    light_gradient_start:'#F5F7FA',
    light_gradient_end:'#E4E7EB',
    blue_gradient_start:'#40C3F7',
    blue_gradient_end:'#0B69A3',
    green_gradient_start:'#2DCC9A',
    green_gradient_end:'#01644F',
    orange_gradient_start:'#FF9240',
    orange_gradient_end:'#B14B01',
    red_gradient_start:'#FF5D40',
    red_gradient_end:'#B11801',
    background_gradient_start:'#1992D4',
    backgroung_gradient_end:'#035388',
  },
  SETTINGS: {
    '1': ['#52606D', '#9AA5B1'], // conlor gray (intallations)
    '2': ['#D04F53', '#E99793'], // color red
    '3': ['#965379', '#C096AC'], // color purple
    '4': ['#007297', '#94C4D4'], // color blue
    '5': ['#008D84', '#75BAB3'], // color green
    '6': ['#FFB837', '#FFD38B'], // color yellow
  }
}

const DEFAULT_LOGO = process.env.VUE_APP_DEFAULT_LOGO;

const IS_MOBILE = process.env.VUE_APP_MOBILE;

const PLATFORM = {
  IOS: 'ios',
  ANDROID: 'android',
  WEB: undefined
}

const NOTIFICATIONS = {
  ITEMS: 10
}

// Export list

export {
  COLORS,
  DEFAULT_LOGO,
  IS_MOBILE,
  PLATFORM,
  NOTIFICATIONS
}

export default {

  APP_ENV: process.env.VUE_APP_APP_ENV,

  DEFAULT_LOGO,

  /**
  * IS_MOBILE
  *
  * @type {Boolean} - Indica si la aplicación se ha compilado para móviles
  */
  IS_MOBILE,

  PLATFORM,

  /**
   * IS_PRODUCTION
   *
   * @constant {Boolean} - Indica si la aplicación se ha compilado en modo producción o desarrollo
   */
  IS_PRODUCTION: process.env.NODE_ENV === 'production',
  IS_DEV: process.env.VUE_APP_APP_ENV === 'dev',
  IS_PRE: process.env.VUE_APP_APP_ENV === 'pre',

  /**
   * SETTINGS
   *
   * @constant {Object} SETTINGS - Ajustes de la aplicación
   * @property {Number} ARCADE_MODE_TIME - Tiempo de espera en milisegundos para evitar enviar muchos eventos seguidos
   * @property {Boolean} AUTH - Indica si la aplicación tiene autenticación
   * @property {String} DEFAULT_MODE - Indica el entorno seleccionado cuando se inicia la aplicación en desarrollo: 'DEV', 'PRE', 'PROD'
   * @property {String} DEFAULT_ROUTE - Nombre de la ruta por defecto
   * @property {Boolean} ON_BOARD - Indica si existe una presentación al iniciar la app por primera vez
   */
  SETTINGS: {
    HAS_ON_BOARD: process.env.VUE_APP_HAS_ON_BOARD,
    HAS_GOOGLEMAP: process.env.VUE_APP_HAS_GOOGLEMAP,
    HAS_AUTHENTICATION: process.env.VUE_APP_HAS_AUTHENTICATION,
    ARCADE_MODE_TIME: process.env.VUE_APP_ARCADE_MODE_TIME,
    DEFAULT_MODE: process.env.NODE_ENV,
    DEFAULT_ROUTE: process.env.VUE_APP_DEFAULT_ROUTE,
    DEBOUNCE_WAIT_TIME: 900,
    THROTTLE_WAIT_TIME_SHORT: 400,
  },

  /**
   * @constant {String} SUPPORT_URL - URL de soporte
   */
  SUPPORT_URL: process.env.VUE_APP_AZCLOUD_SUPPORT_URL,

  /**
   * @constant {String} SUPPORT_FORM_URL - URL formulario de soporte
   */
  SUPPORT_FORM_URL: process.env.VUE_APP_AZCLOUD_HELP_URL,

  /**
   * @typedef {Object} CONNECT_MODE
   * @property {String} AZCLOUD_API_URL - URL del api de AirzoneCloud
   * @property {String} AZCONTROL_API_URL - URL del api de AirzoneControl
   * @property {String} AZCONTROL_API_KEY - Api key de AirzoneControl
   */

  /**
   * @typedef {Object} CONNECT_GOOGLEMAP
   * @property {String} IOS_KEY - Key para dispostivos iOS
   * @property {String} ANDROID_KEY - Key para dispositivos Android
   */

  /**
   * @typedef {Object} CONNECT_ALGOLIA
   * @property {String} CLIENT_ID - ID de cliente para el buscador de Algolia
   * @property {String} API_KEY - Key para el buscador de Algolia
   */

  /**
   * CONNECT
   *
   * @constant {Object} CONNECT - Parámetros de conexión con el backend
   * @property {CONNECT_MODE} DEV - Parámetros de conexión en entorno DEV
   * @property {CONNECT_MODE} PRE - Parámetros de conexión en entorno PRE
   * @property {CONNECT_MODE} PROD - Parámetros de conexión en entorno PROD
   * @property {CONNECT_GOOGLEMAP} GOOGLEMAP - Parámetros de conexión de Google Map
   * @property {CONNECT_ALGOLIA} ALGOLIA - Parámetros de conexión de Algolia
   * @property {CONNECT_WS} WS - Parámetros de conexión con el webserver wifi
   */
  CONNECT: {
    APP: {
      AZCLOUD_BASE_URL: process.env.VUE_APP_AZCLOUD_BASE_URL,
      AZCLOUD_API_URL: process.env.VUE_APP_AZCLOUD_API_URL,
      AZCLOUD_API_V2_URL: process.env.VUE_APP_AZCLOUD_API_V2_URL,
      AZCLOUD_SOCKET_PATH: process.env.VUE_APP_AZCLOUD_SOCKET_PATH,
      AZCONTROL_API_URL: process.env.VUE_APP_AZCONTROL_API_URL,
      AZCONTROL_API_KEY: process.env.VUE_APP_AZCONTROL_API_KEY,
    },
    GOOGLEMAP: {
      API_URL: process.env.VUE_APP_GOOGLEMAP_API_URL,
      KEY: process.env.VUE_APP_GOOGLEMAP_KEY,
    },
    ALGOLIA: {
      CLIENT_ID: process.env.VUE_APP_ALGOLIA_CLIENT_ID,
      API_KEY: process.env.VUE_APP_ALGOLIA_API_KEY,
    },
    WS: process.env.VUE_APP_WS_API_URL,
    ROLLBAR: {
      TOKEN: process.env.VUE_APP_ROLLBAR_TOKEN,
      ACTIVE: process.env.VUE_APP_ROLLBAR_ACTIVE
    },
    GET_WIFIS: {
      USER: process.env.VUE_APP_GET_WIFIS_USER,
      PASS: process.env.VUE_APP_GET_WIFIS_PASS
    },
    LOCATION: {
      API_URL: process.env.VUE_APP_LOCATION_API_URL
    }
  },

  /**
   * LANGUAGE
   *
   * @constant {Object} LANGUAGE - Parámetros de configuración de los idiomas
   * @property {String} DEFAULT - Idioma seleccionado por defecto
   * @property {String} FALLBACK - Idioma utilizado si no se encuentra una traducción en el idioma seleccionado
   */
  LANGUAGE: {
    DEFAULT: process.env.VUE_APP_DEFAULT_LANGUAGE || 'en',
    FALLBACK: process.env.VUE_APP_DEFAULT_LANGUAGE || 'en',
  },

  /**
   * LANGUAGE
   *
   * @constant {Object} LANGUAGES - Listado de idiomas disponibles
   */
  LANGUAGES: process.env.VUE_APP_LANGUAGES, // () => getListLanguages(process.env.VUE_APP_LANGUAGES),

  /**
   * UNITS
   * Tipo de unidad de temperatura del usuario recibido en el backend
   *
   * @constant {Object} UNITS - Valores de las unidades de temperatura
   */
  UNITS: {
    CELSIUS: 0,
    FARENHEIT: 1,
  },

  /**
   * TEMP_UNITS
   * Tipo de unidad de temperatura utilizada para enviarnos las temperaturas desde el backend
   *
   * @constant {Object} TEMP_UNITS - Nombres de las unidades de temperatura
   */
  TEMP_UNITS: {
    CELSIUS: 'celsius',
    FARENHEIT: 'fah',
  },

  /**
   * @constant {Object} - Links de las App stores
   * @property {String} IOS - Links de la aplicación para iOS
   * @property {String} ANDROID - Links de la aplicación para Android
   */
  STORE: {
    IOS: process.env.VUE_APP_STORE_IOS,
    IOS_DOMAIN: process.env.VUE_APP_STORE_IOS_DOMAIN,
    IOS_URL: process.env.VUE_APP_STORE_IOS_URL,
    ANDROID: process.env.VUE_APP_STORE_ANDROID,
  },

  /**
   * @constant {Object} REGEX Regular expressions to validate forms
   * @property {String} EMAIL - Regular expression of email
   * @property {String} NAME - Regular expression of name
   */
  REGEX: {
    EMAIL: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i,
    // NAME: /^[a-zA-Z0-9 ñàâäáèêëéìîïíòôóöùûüúÿçÑÀÂÄÁÈÉÊËÉÎÏÍÔÓÖÙÛÜÚŸÇ-\s_,.!¿?()]{2,35}$/i,
    NAME: /^.{2,35}$/i,
    MAC_DIGITS: /([a-f0-9]{2})([a-f0-9]{2})/i,
    MAC_ADDRESS:  /^(([A-Fa-f0-9]{2}[:]){5}[A-Fa-f0-9]{2}[,]?)+$/i,
    ZONE_NAME: /^[!\-+., @#*%&/():;¿?_ÁÉÍÓÚÀÈÌÒÙÂÊÎÔÛÄËÏÖÜÃÕÑÇA-Záéíóúàèìòùâêîôûäëïöüãõñça-z0-9]{1,11}$/i,
    IPV4: /^(\d{1,3}\.){3}\d{1,3}$/,
    IPV6: /^([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}$/,
    MASK: /^(255\.){3}(255|254|252|248|240|224|192|128|0)|(255\.(255|254|252|248|240|224|192|128|0)\.0\.0)|(255\.255\.(255|254|252|248|240|224|192|128|0)\.0)|(255\.255\.255\.(255|254|252|248|240|224|192|128|0))$/,
  },

  NETWORK: {
    ONLINE: 'online',
    UNKNOWN: 'Unknown connection',
    ETHERNET: 'Ethernet connection',
    WIFI: 'WiFi connection',
    CELL_2G: 'Cell 2G connection',
    CELL_3G: 'Cell 3G connection',
    CELL_4G: 'Cell 4G connection',
    CELL_5G: 'Cell 5G connection',
    CELL: 'Cell generic connection',
    NONE: 'offline',
    NOT_CONNECTED_TO_INTERNET: 'NOT_CONNECTED_TO_INTERNET',
    CONNECTED_TO_INTERNET: 'IS_CONNECTED_TO_INTERNET',
  },

  RATE_PERIODS: {
    HIGH: 1,
    MID: 2,
    LOW: 3
  },

  /**
  * @constant {number} UPDATE_DEVICE_DELAY - Máximo tiempo de espera de obtener respuesta detectando sistemas
  * @constant {number} MAX_TIME_DETECTING_SYSTEM - Máximo tiempo de espera de obtener respuesta detectando sistemas
  * @constant {number} MAX_TIME_WAIT_FINISH_DISCOVERY - Máximo tiempo de espera de obtener respuesta del evento "finishDiscovery" por socket
  */
  TIMEOUT: {
    UPDATE_DEVICE_DELAY: 500,
    MAX_TIME_DETECTING_SYSTEM: 120000,
    MAX_TIME_WAIT_FINISH_DISCOVERY: 15000,
    MAX_WAITING_ONRESUME_CLEAN_TOAST_STATE: 6000, // Originalmente se puso 20s, pero era demasiado. Se reduce a 6s (suficientes para obtener información al volver de segundo plano)
    MAX_WAITING_ONRESUME_LOADING_APP_STATE: 20000,
  },

  /**
  * @constant {Object} - Usamos para comparación de objetos
  */
  equal: {
    NOT_EQUAL:0,
    VALUE: 1,
    VALUE_AND_TYPE: 2,
    PROPERTIES: 3,
    PROPERTIES_AND_TYPE: 4
  },

  MENU_ITEMS: {
    SHOWED: 4,
    HIDDEN: 2,
  },

  /**
   * @constant {Object} - Colores de la aplicación.
   * SETTINGS. mapea los colores de ajustes del sitio con los degradados
   */
  COLORS
};
