/**
   * USER_TYPE
   *
   * @constant {Object} USER_TYPE - Tipos de usuario
   * @property {String} ADMIN - Usuario administrador
   * @property {String} BASIC - Usuario básico
   */
const USER_TYPE = {
  ADMIN: 'admin',
  ADVANCED: 'advanced',
  BASIC: 'basic',
}
/**
 * SEMANTIC_DEVICE_GROUP_TYPE: Agrupación semántica de dispositivos.
 * Principalmente usada para la visualización de dispositivos en la interfaz
 */
const SEMANTIC_DEVICE_GROUP_TYPE = {
  climatization: 'climatization',
  iaq: 'iaq',
  acs: 'acs',
  others: 'others',
  clamp: 'clamp'
};

/**
 * MODEL_TYPE: Tipos de dispositivos que usamos en nuestro modelo (frontend)
 */
const MODEL_TYPE = {
  DEVICE: 'DEVICE',
  ACS: 'ACS',
  VMC: 'VMC',
  ZONE: 'ZONE',
  RELAY: 'RELAY',
  CLAMP: 'CLAMP',
  OUTPUTS: 'OUTPUTS',
  SYSTEM: 'SYSTEM',
  PURIFIER: 'PURIFIER',
  CCP: 'CCP',
  DEHUMIDIFIER: 'DEHUMIDIFIER'
}

const AQ_COLORS = {
  aq_bad: '#EF694E',
  aq_good: '#2DCC9A',
  aq_regular: '#F8BA68'
}

/**
 * DEVICE_TYPE: Tipos de dispositivos desde el backend
 */
const DEVICE_TYPE = {
  az_ws: 'az_ws', // webserver airzone
  aidoo: 'aidoo',
  aidoo_it: 'aidoo_it',
  az_8cb: 'az_8cb', // central
  az_zone: 'az_zone',
  az_acs: 'az_acs',
  aidoo_acs: 'aidoo_acs',
  az_vmc: 'az_vmc',
  aidoo_vmc: 'aidoo_vmc',
  az_relay: 'az_relay',
  az_energy_clamp: 'az_energy_clamp',
  az_system: 'az_system',
  az_airqsensor: 'az_airqsensor',
  az_outputs: 'az_outputs',
  az_device: 'az_device',
  az_ws_purifier: 'az_ws_purifier',
  aidoo_purifier: 'aidoo_purifier',
  az_dehumidifier: 'az_dehumidifier',
  az_ccp: 'az_ccp',
}

// Webserver types for data-interface
const WS_TYPE = {
  ws_aidoo: 'ws_aidoo',
  ws_az: 'ws_az',
}

// Convert WS_TYPE to data-interface
const WS_TYPE_TO_DATA_INTERFACE = {
  aidoo: WS_TYPE.ws_aidoo,
  aidoo_it: WS_TYPE.ws_aidoo,
  az_ws: 'ws_az',
}

const SCHED_TYPE = {
  calendar: 'calendar',
  week: 'week',
  acs: 'acs',
  vmc: 'vmc',
  relay: 'relay',
}

const AQ_QUALITY_VALUES = {
  good: 'good',
  bad: 'bad',
  regular: 'regular',
  night_mode: 'night_mode',
}

const AQ_VENT_MODE_VALUES = {
  auto: 'auto',
  on: 'on',
  off: 'off',
}


const THIRD_PARTY_TYPES = {
  honeywell: 'honeywell',
  nest: 'nest',
  ecobee: 'ecobee',
}

const CURRENCY = {
  EUR: 'EUR',
  USD: 'USD',
  GBP: 'GBP'
}

const CURRENCY_SYMBOL = {
  EUR: '€',
  USD: '$',
  GBP: '£'
}

const DEVICES_TYPES_TO_BROADCAST = {
  [SEMANTIC_DEVICE_GROUP_TYPE.climatization]: [DEVICE_TYPE.aidoo_it, DEVICE_TYPE.aidoo, DEVICE_TYPE.az_zone, DEVICE_TYPE.az_system],
  [SEMANTIC_DEVICE_GROUP_TYPE.acs]: [DEVICE_TYPE.az_acs, DEVICE_TYPE.aidoo_acs],
  [SEMANTIC_DEVICE_GROUP_TYPE.iaq]: [DEVICE_TYPE.az_vmc, DEVICE_TYPE.aidoo_vmc],
  [SEMANTIC_DEVICE_GROUP_TYPE.others]: [DEVICE_TYPE.az_relay],
}

const DEVICES_NAME_EDITABLE = [
  DEVICE_TYPE.aidoo,
  DEVICE_TYPE.aidoo_it,
  DEVICE_TYPE.az_zone,
  DEVICE_TYPE.az_relay,
  DEVICE_TYPE.az_acs,
  DEVICE_TYPE.aidoo_acs,
  DEVICE_TYPE.az_vmc,
  DEVICE_TYPE.aidoo_vmc,
  DEVICE_TYPE.az_airqsensor,
  DEVICE_TYPE.az_energy_clamp
]

const WS_CODE_TO_TYPE = {
  0: {
    type: DEVICE_TYPE.az_ws,
    name: 'K70 Wifi'
    },
  1: {
    type: DEVICE_TYPE.az_ws,
    name: 'K70 Ethernet'
  },
  2: {
    type: DEVICE_TYPE.az_ws,
    name: 'Webserver 5G'
  },
  3: {
    type: DEVICE_TYPE.az_ws,
    name: 'Webserver HUB'
  },
  4: {
    type: DEVICE_TYPE.az_8cb,
    name: 'Central BLE'
  },
  5: {
    type: DEVICE_TYPE.aidoo,
    name: 'Aidoo'
  },
  6: {
    type: DEVICE_TYPE.aidoo,
    name: 'Aidoo PRO'
  },
  7: {
    type: DEVICE_TYPE.aidoo,
    name: 'Aidoo PRO Fancoil'
  },
  8: {
    type: DEVICE_TYPE.az_airqsensor,
    name: 'AirQ Sensor'
  },
  9: {
    type: DEVICE_TYPE.az_energy_clamp,
    name: 'AZ Energy Clamp'
  }
}
/**
* VISTUALIZATION TYPE
*
* @constant {Object} VISUALIZATION - Tipo de visualización de instalaciones o dispositivos
*/
const VISUALIZATION = {
  CLASSIC: 'classic',
  COMPACT: 'compact',
  LIST: 'list',
  ORDER: 'order'
}

const DEVICE_TO_MODEL_TYPE = {
  [DEVICE_TYPE.az_device]: MODEL_TYPE.DEVICE,
  [DEVICE_TYPE.az_system]: MODEL_TYPE.SYSTEM,
  [DEVICE_TYPE.az_zone]: MODEL_TYPE.ZONE,
  [DEVICE_TYPE.aidoo]: MODEL_TYPE.ZONE,
  [DEVICE_TYPE.aidoo_it]: MODEL_TYPE.ZONE,
  [DEVICE_TYPE.az_acs]: MODEL_TYPE.ACS,
  [DEVICE_TYPE.aidoo_acs]: MODEL_TYPE.ACS,
  [DEVICE_TYPE.az_vmc]: MODEL_TYPE.VMC,
  [DEVICE_TYPE.aidoo_vmc]: MODEL_TYPE.VMC,
  [DEVICE_TYPE.az_airqsensor]: MODEL_TYPE.VMC,
  [DEVICE_TYPE.az_relay]: MODEL_TYPE.RELAY,
  [DEVICE_TYPE.az_dehumidifier]: MODEL_TYPE.DEHUMIDIFIER,
  [DEVICE_TYPE.az_energy_clamp]: MODEL_TYPE.CLAMP,
  [DEVICE_TYPE.az_outputs]: MODEL_TYPE.OUTPUTS,
  [DEVICE_TYPE.az_ws_purifier]: MODEL_TYPE.PURIFIER,
  [DEVICE_TYPE.aidoo_purifier]: MODEL_TYPE.PURIFIER,
  [DEVICE_TYPE.az_ccp]: MODEL_TYPE.CCP,
}

const DEVICE_TO_SEMANTIC_TYPE = {
  [DEVICE_TYPE.az_zone]: SEMANTIC_DEVICE_GROUP_TYPE.climatization,
  [DEVICE_TYPE.aidoo]: SEMANTIC_DEVICE_GROUP_TYPE.climatization,
  [DEVICE_TYPE.aidoo_it]: SEMANTIC_DEVICE_GROUP_TYPE.climatization,
  [DEVICE_TYPE.az_acs]: SEMANTIC_DEVICE_GROUP_TYPE.acs,
  [DEVICE_TYPE.aidoo_acs]: SEMANTIC_DEVICE_GROUP_TYPE.acs,
  [DEVICE_TYPE.az_vmc]: SEMANTIC_DEVICE_GROUP_TYPE.iaq,
  [DEVICE_TYPE.aidoo_vmc]: SEMANTIC_DEVICE_GROUP_TYPE.iaq,
  [DEVICE_TYPE.az_airqsensor]: SEMANTIC_DEVICE_GROUP_TYPE.iaq,
  [DEVICE_TYPE.az_relay]: SEMANTIC_DEVICE_GROUP_TYPE.others,
  [DEVICE_TYPE.az_energy_clamp]: SEMANTIC_DEVICE_GROUP_TYPE.clamp,
}

/**
 * ALTHERMA
 *
 * @constant {Array} ALTHERMA - Prefijos en los nombres de unidades Altherma
 */
const ALTHERMA = ['AZWSDA2', 'AZWSDK2']

/**
 * MODES
 *
 * @constant {Object} MODES - Valores de los modos
 */
const MODES = {
  STOP: 0,
  AUTO: 1,
  COOLING: 2,
  HEATING: 3,
  FAN: 4, // vent
  DRY: 5, // deshum
  EMERGENCY_HEAT: 6,
  HEAT_AIR: 7,
  HEAT_RADIANT: 8,
  HEAT_COMB: 9,
  COOL_AIR: 10,
  COOL_RADIANT: 11,
  COOL_COMB: 12,
  BYPASS: 13,
  RECOVERY: 14,
  REGULATION_TEMP: 15,
  PURIFICATION: 16,
  FAN_PURIFICATION: 17,
  FAN_ENERGY: 18,
  CLOUD_SP_KEYS: {
    0: 'setpoint_air_stop',
    1: 'setpoint_air_auto',
    2: 'setpoint_air_cool',
    3: 'setpoint_air_heat',
    4: 'setpoint_air_vent',
    5: 'setpoint_air_dry',
    6: 'setpoint_air_emerheat',
    7: 'setpoint_air_heat',
    8: 'setpoint_air_heat',
    9: 'setpoint_air_heat',
    10: 'setpoint_air_cool',
    11: 'setpoint_air_cool',
    12: 'setpoint_air_cool'
  }
}

const WEB_MODES = {
  STOP: 'stop',
  AUTO: 'auto',
  COOLING: 'cool',
  HEATING: 'heat',
  FAN: 'fan', // vent
  DRY: 'dry', // deshum
  EMERGENCY_HEAT: 'emergencyHeat',
  HEAT_AIR: 'heat_air',
  HEAT_RADIANT: 'heat_radiant',
  HEAT_COMB: 'heat_comb',
  COOL_AIR: 'cool_air',
  COOL_RADIANT: 'cool_radiant',
  COOL_COMB: 'cool_comb',
  BYPASS: 'bypass',
  RECOVERY: 'recovery',
  REGULATION_TEMP: 'regulation_temp',
  PURIFICATION: 'purification',
  FAN_PURIFICATION: 'fan_purification',
  FAN_ENERGY: 'fan_energy',
}

const DEFAULT_BUTTONS_ORDER = [
  'aq_vent_mode_conf',
  'mode',
  // 'airq',
  'power',
  'powerful_mode',
  'sleep',
  'timer',
  'speed_conf',
  'speed_conf_2',
  'zone_sched_available',
  'aq_mode_conf',
  'settings',
  'eco_conf',
  'usermode_conf',
  'graphics',
]

const DEFAULT_NUM_VISIBLE_BUTTONS = 4;

const SCENES = {
  MAX_NUM_SCENES: 25,
  MAX_NUM_ACTIONS: 10
}

const PERIODS = {
  MAX_NUM_PERIODS: 10,
  MAX_NUM_TIMESLOTS: 10
}

const AQ_MODE = {
  ON: 'on',
  OFF: 'off',
  AUTO: 'auto'
}

const VMC_MODE = {
  ON: 'manual_off',
  OFF: 'manual_on'
}

/**
 * TEMP_STEPS
 *
 * @constant {Object} TEMP_STEPS - Valores de los saltos de temperatura (Indica si la temperatura admite decimales o no)
 */
const TEMP_STEPS = {
  DECIMAL: 0,
  INTEGER: 1,
}

/**
 * TEMP
 *
 * @constant {Object} TEMP - Valores MÁX y MIN por defecto de temperaturas en C (Centrígrados) o F (Fah)
 */
const TEMP = {
  DEFAULT_MIN: {
    celsius: 15,
    fah: 59
  },
  DEFAULT_MAX: {
    celsius: 30,
    fah: 86
  }
}

/**
 * SLATS_MODE
 *
 * @constant {Object} SLATS_MODE - Posiciones de las lamas
 */
const SLATS_MODE = {
  AUTO: 8,
  SWING: 9,
}

/**
 * SPEED_TYPE
 *
 * @constant {Object} SPEED_TYPE - Tipo de velocidad
 */
const SPEED_TYPE = {
  NORMAL: 0,
  AIRFLOW: 1,
}

/**
 * ECO_TYPE
 *
 * @constant {Object} ECO_TYPE - Configuraciones de eco
 */
const ECO_TYPE = {
  OFF: 'off',
  MANUAL: 'manual',
  ECOA: 'a',
  ECOAA: 'a_p',
  ECOAAA: 'a_pp',
}

/**
 * USERMODE
 *
 * @constant {Object} USERMODE - Modos de usuario
 */
const USERMODE = {
  STOP: 'stop',
  COMFORT: 'comfort',
  UNOCCUPIED: 'unoccupied',
  NIGHT: 'night',
  ECO: 'eco',
  VACATION: 'vacation',
  VLA_ON: 'vla_on',
  VLA_OFF: 'vla_off'
}

/**
 * STAGES
 *
 * @constant {Object} STAGES - Posiciones de las lamas
 */
const STAGES = {
  OFF: 'off',
  AIR: 'air',
  RADIANT: 'radiant',
  COMBINED: 'combined',
}

/**
 * ICONS
 *
 * @constant {Object} ICONS - Número de iconos disponibles
 */
const ICONS = {
  SCENES: 24,
  GROUPS: 20,
}

/**
 * WIFI_STATUS
 *
 * @constant {Object} WIFI_STATUS - Estados de la conexión WiFi
 * @property {Number} DISCONNECTED - El dispositivo ha perdido la conexión con la red WiFi
 * @property {Number} CONNECTING - El dispositivo está intentando conectar con la red WiFi
 * @property {Number} WIFI_CONNECTED - El dispositivo está conectado a la red WiFi
 * @property {Number} CLOUD_CONNECTED - El dispositivo está conectado a la Cloud
 * @property {Number} ERROR - El dispositivo no puede conectar con la red WiFi, podría ser por introducir una contraseña erronea
 */
const WIFI_STATUS = {
  DISCONNECTED: 0,
  CONNECTING: 1,
  WIFI_CONNECTED: 2,
  CLOUD_CONNECTED: 3,
  ERROR: 4,
}

/**
 * BLUETOOTH
 *
 * @constant {Object} BLUETOOTH - Parámetros de configuración del Bluetooth
 * @property {Number} MIN_LEVEL_SIGNAL - Intensidad mínima de señal para poder conectar con un dispositivo
 * @property {Number} MAX_TIME_SCAN_DEVICES - Tiempo máximo que estará buscando dispositivos en milisegundos
 * @property {Array} DEVICES_PREFIXES - Listado con los prefijos validos en el nombre para buscar un dispositivo
 * @property {String} SERVICE_UUID - Service UUID
 * @property {String} CHARACTERISTIC_UUID - Característica UUID
 * @property {Number} MAX_TIME_GET_DATA - Tiempo máximo de espera cuando pedimos datos a un dispositivo en milisegundos (Por defecto)
 * @property {Number} MAX_TIME_GET_DATA_EXTENDED - Tiempo máximo de espera cuando pedimos datos a un dispositivo en milisegundos (Extendido, para comandos que van a tardar más tiempo)
 * @property {Number} SLEEP_TIME_BETWEEN_REQUEST - Tiempo de espera entre peticiones cuando hacemos polling en milisegundos
 * @property {Array} RETRY_EXCLUDE_CMD - Lista de comandos que no se incluyen en el proceso de reintentos y manejan error 14 para compatibilidad con viejos webservers
 * @property {Array} SLEEP_TIME_WRITE_TO_READ_CMD - Lista de comandos que introducen retardo de lectura tras la escritura para dar tiempo a central a escribir en el buffer.
 */
const BLUETOOTH = {
  NUM_MAX_RETRYS: 6, // Num reintentos cuando se reinicia todo un proceso BLE tras error de conexión o lectura de buffer
  MIN_LEVEL_SIGNAL: {
    ios: -83,
    android: -80
  },
  MAX_TIME_SCAN_DEVICES: 5000,
  DEVICES_PREFIXES: ['AZWS', 'AIT', 'AZCLOUD', 'AZP', 'AZW5G', 'AZ8CB'],
  DEVICES_PREFIXES_AIRTOOLS: ['AZWS', 'AIT', 'AZCLOUD', 'AZP', 'AZW5G', 'AZ8CB'],
  DEVICE_TYPES_FORCE_REQUESTMTU_BUFFERSIZE: [
    DEVICE_TYPE.az_8cb,
    DEVICE_TYPE.az_airqsensor,
    DEVICE_TYPE.az_energy_clamp,
  ],
  SERVICE_UUID: 'AABB',
  CHARACTERISTIC_UUID: 'C300',
  MAX_TIME_GET_DATA: 20000,
  MAX_TIME_GET_DATA_EXTENDED: 100000,
  SLEEP_TIME_BETWEEN_REQUEST_EXTENDED: 1000,
  SLEEP_TIME_BETWEEN_REQUEST: 90, // 100, <- secure sleep_time_between_request. (experimental: 90) 10% fastest
  BUFFER_SIZE: 512,
  // El tamaño máximo de buffer que permite Android de momento por standard es 517. Tratamos de forzarlo al máximo para evitar problemas si el requestMtu es inferior
  // al tamaño de envío del dispositivo (Android fuerza en tal caso un stack de 600 bytes que se rellena de forma cícilica cada (requestMtuBufferSize -1))
  REQUEST_MTU_BUFFER_SIZE: 517, // 512, <- secure request_mtu_buffer_size.
  MAX_READ_BUFFER_ATTEMPS: 2,
  RETRY_EXCLUDE_CMD: [
    '"cmd":"connect"',
    '"cmd":"info"',
    '"cmd":"scan"',
  ],
  MAX_TIME_GET_DATA_EXTENDED_CMD: [
    'detectSystems',
    'getDeviceWifis',
    'getTopologyInfo',
    'connectDeviceToWifi',
    'resetWebserver',
    'getWsSchedules',
    'saveWsSchedule',
    'deleteWsSchedule',
    'setMdbuConf',
    'getMachineReady',
    'local_discover'
  ],
  SLEEP_TIME_WRITE_TO_READ_CMD: [
    '{"info_ws_sched": {}}',
    '"mod_ws_sched"',
    '"del_ws_sched"',
    '"getparams"',
    '"get"',
    '"set"'
  ]
}

/**
 * COVERAGE
 *
 * @constant {Object} COVERAGE - Intensidad de señal
 */
const COVERAGE = {
  // WIFI: {
  //   HIGHEST: 220,
  //   HIGH: 210,
  //   MEDIUM: 200,
  //   LOW: 190,
  //   LOWEST: 180,
  // },
  HIGHEST: -55,
  HIGH: -60,
  MEDIUM: -70,
  LOW: -75,
  LOWEST: -80,
}

const UTILS = {
  ITEMS_FOR_FRAGMENT: 10
}

const SCHEDULES = {
  MAX_SCHEDULES_NUM: 24
}

/**
 * TAI
 *
 * @constant {Object} TAI - Modo de funcionamiento de la máquina TAI TH
 */
const TAI = {
  AIR: true,
  WATER: false,
}

/**
* TYPE del parámetro para generarlo en el formulario
*
* @constant {Object} INPUT_TYPE
* @property {String} CHECKBOX
* @property {String} SELECT
* @property {String} TEXT
 */
const INPUT_TYPE = {
  checkbox: 'checkbox',
  select: 'select',
  text: 'text',
}

const INSTALLATION_SCHEDULE_TYPES = {
  calendar: 'calendar',
  week: 'week',
  acs: 'acs'
}

const PAGINATION = {
  INSTALLATIONS: {
    items_for_page: 9
  },
  WEBSERVERS: {
    items_for_page: 10
  },
  AIDOOS: {
    items_for_page: 9
  }
}
/**
 * CONSTANTES confi protocolo comunicación Aidoo vía BLE
 *
 * @property {Array<Number>} TYPE - Tipo de protocolo com. Modbus o BACnet
 * @property {Array<Number>} SPEEDS - Velocidades modbus y BACnet
 */
const PROTOCOL = {
  TYPE: [0, 1],
  SPEEDS: [300, 600, 1200, 2400, 4800, 9600, 19200, 38400, 57600, 76800, 115200],
}

const AUX_HEAT = {
  LOCKOUT_TEMP_VALUES: [-178, -150, -122, -94, -67, -39, -11, 17, 44, 72, 100, 128, 156, 183],
  TON_VALUES: [-40, -35, -30, -25, -20, -15],
  TOFF_VALUES: [5, 0, -5, -10, -15, -20],
  TDELAY_VALUES: [0, 5, 10, 15, 20, 25, 30]
}

const FALLBACK = {
  ALPHA: [3, 4, 7],
  BETA: [3, 4, 7],
  GPIO_FAN_SPEEDS: [1, 2, 3, 4, 5, 6],
  GPIO_STOP_TIMES: [60, 120, 180, 240, 300, 360, 420, 480, 540, 600, 660, 720, 780, 840, 900, 960, 1020, 1080, 1140, 1200, 1260, 1320, 1380, 1440, 1500, 1560, 1620, 1680, 1740, 1800, 3600, 7200],
  GPIO_MIN_RUN_TIMES: [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
}

const STAGES_TYPE = {
  air: 'air',
  radiantc: 'radiantc',
  trv: 'trv',
  relay: 'relay',
}

const STAGES_MODE = {
  cool: 'cool',
  heat: 'heat',
}

const STAGES_NAME = {
  off: 'off',
  air: 'air',
  radiant: 'radiant',
  combined: 'combined'
}

const AZ_AIR_TYPES_SS_MAP = {
  dx: 'dx',
  fancoil: 'fancoil'
}

const AZ_RADIANTC_TYPES_SS_MAP = {
  floor: 'floor',
  ceiling_rad: 'ceiling_rad',
  electric: 'electric',
  trv: 'trv'
}

const TIMERS ={
  air_output_test: 15 * 60 * 1000 + 10000 // Test en sistema dura 15 min. Dejamos un pequeño margen de seguridad de 10 segundos
}

const OUTPUTS_PARAMS_REGEX = /^(air_|radiantc_|trv_)\d+/g;

const WEBSERVER_AIRZONE_ICON = {
  airqsensorWifi: 'sensor',
  wifi5G: 'wifi5G',
  hub: 'hub',
  azmeterWifi: 'meter-airzone',
}

const WEBSERVER_HARDWARE = {
  airqsensorWifi: 'airqsensorWifi',
  wifi5G: 'wifi5G',
  hub: 'hub',
  azmeterWifi: 'azmeterWifi',
}

const GRADIENTS = {
  default: [
    [154, 165, 177],
    [81, 94, 107],
  ],
  cooling: [
    [64, 195, 247],
    [11, 105, 163],
  ],
  airing: [       // Verde
    [45, 204, 154],
    [1, 100, 79],
  ],
  drying: [
    [64, 195, 247],
    [11, 105, 163],
  ],
  heating: [
    [238, 104, 77],
    [208, 28, 15],
  ],
  powerful: [
    [238, 104, 77],
    [208, 28, 15],
  ],
  bad: [
    [238, 104, 77],
    [208, 28, 15],
  ],
  success: [      // Verde
    [45, 204, 154],
    [1, 100, 79],
  ],
  good: [         // Verde
    [45, 204, 154],
    [1, 100, 79],
  ],
  stop: [         // Verde
    [45, 204, 154],
    [1, 100, 79],
  ],
  preparing: [      // Verde
    [45, 204, 154],
    [1, 100, 79],
  ],
  regular: [
    [255, 146, 64],
    [177, 75, 1],
  ],
  medium: [
    [229, 156, 0],
    [255, 190, 50],
  ],
  demmandOff: [
    [234, 238, 244],
    [213, 218, 224],
  ],
  relayActive: [
    [229, 156, 0],
    [255, 190, 50],
  ],
  error: [
    [234, 238, 244],
    [213, 218, 224],
  ],
  orange: [
    [255, 146, 64],
    [177, 75, 1],
  ]
}

const GRAPHICS = {
  RANGE: {
    day: 'day',
    month: 'month',
    year: 'year'
  },

  SMOOTH_FILTER_RANGE: {
    active: 60 * 5,
    setpoint: 0,
  },

  PRECISION: {
    minutes: 5,
    hours: 1
  },

  COLORS: {
    default: '#9AA5B1',
    work_temp: '#079A75',
    weather: '#F8BA68',
    setpoint: '#1992D4',
    confort: alpha => alpha ? `rgba(45, 204, 154,${alpha})` : '#2DCC9A',
    heating: alpha => alpha ? `rgba(239, 105, 78,${alpha})` :'#EF694E',
    cooling: alpha => alpha ? `rgba(25, 146, 212,${alpha})` :'#1992D4',
    // confort: 'rgba(45, 204, 154, 0.45)',
    // heating: 'rgba(239, 105, 78, 0.45)',
    // cooling: 'rgba(25, 146, 212, 0.45)',
    temp: '#1992D4',
    rh: '#F8BA68',
    co2: '#EF694E',
    pm2_5: '#079A75',
    pm10: '#994A00',
    tvoc: '#52606D',
    aqi_1: '#00AAA1',
    aqi_2: '#FFB837',
    aqi_3: '#F3975B',
    aqi_4: '#D04F53',
    aqi_5: '#965379',
    aq_bad: AQ_COLORS.aq_bad,
    aq_regular: AQ_COLORS.aq_regular,
    aq_good: AQ_COLORS.aq_good,
    humidity: '#1992D4'
  },
}

const COUNTRY_CODE = {
  france: 'FR'
}

const BUTTON_TESTS_AVAILABLES = {
  1: {
    action: 'testonheat',
    title_translate_key: 'units.heat',
    icon: 'heat',
    isShow: true,
  },
  2: {
    action: 'testoncool',
    title_translate_key: 'units.cool',
    icon: 'cool',
    isShow: true,
  },
  3: {
    action: 'testonfan',
    title_translate_key: 'units.fan',
    icon: 'fan',
    isShow: true,
  },
  4: {
    action: 'testoff',
    title_translate_key: 'addDevice.switchOff',
    icon: 'power',
    isShow: true,
  },
  5: {
    action: 'testonvmc',
    title_translate_key: 'accesibility.vmc_on',
    icon: 'outside-fan-on',
    isShow: true,
  },
  6: {
    action: 'testoffvmc',
    title_translate_key: 'accesibility.vmc_off',
    icon: 'outside-fan-off',
    isShow: true,
  },
  7: {
    action: 'testend',
    isShow: false
  }
}

const PRICE_NOT_FOUND = 'priceNotFound';

const FIXED_TARIFF = 'fixed'
const CUSTOM_TARIFF = 'custom'
const REGULATED_TARIFF = 'regulated'
const TARIFF_TYPES = [REGULATED_TARIFF, FIXED_TARIFF, CUSTOM_TARIFF];
const COLOR_DEFAULT_PERIOD = '#9AA5B1';

const METER_CONF = {
  MONO: 'mono',
  TRIPHASIC: 'tri',
}

export {
  USER_TYPE,
  DEVICE_TYPE,
  CURRENCY,
  MODEL_TYPE,
  WS_TYPE,
  WS_TYPE_TO_DATA_INTERFACE,
  SCHED_TYPE,
  AQ_QUALITY_VALUES,
  AQ_VENT_MODE_VALUES,
  AQ_COLORS,
  THIRD_PARTY_TYPES,
  DEVICE_TO_MODEL_TYPE,
  DEVICES_TYPES_TO_BROADCAST,
  DEVICES_NAME_EDITABLE,
  WS_CODE_TO_TYPE,
  VISUALIZATION,
  SEMANTIC_DEVICE_GROUP_TYPE,
  DEVICE_TO_SEMANTIC_TYPE,
  ALTHERMA,
  MODES,
  WEB_MODES,
  DEFAULT_BUTTONS_ORDER,
  DEFAULT_NUM_VISIBLE_BUTTONS,
  SCENES,
  PERIODS,
  AQ_MODE,
  VMC_MODE,
  TEMP_STEPS,
  TEMP,
  SLATS_MODE,
  SPEED_TYPE,
  ECO_TYPE,
  USERMODE,
  STAGES,
  ICONS,
  WIFI_STATUS,
  BLUETOOTH,
  COVERAGE,
  UTILS,
  SCHEDULES,
  TAI,
  INPUT_TYPE,
  INSTALLATION_SCHEDULE_TYPES,
  PAGINATION,
  PROTOCOL,
  AUX_HEAT,
  FALLBACK,
  STAGES_TYPE,
  STAGES_MODE,
  STAGES_NAME,
  AZ_AIR_TYPES_SS_MAP,
  AZ_RADIANTC_TYPES_SS_MAP,
  TIMERS,
  OUTPUTS_PARAMS_REGEX,
  WEBSERVER_AIRZONE_ICON,
  WEBSERVER_HARDWARE,
  GRADIENTS,
  GRAPHICS,
  COUNTRY_CODE,
  BUTTON_TESTS_AVAILABLES,
  PRICE_NOT_FOUND,
  TARIFF_TYPES,
  FIXED_TARIFF,
  CUSTOM_TARIFF,
  REGULATED_TARIFF,
  COLOR_DEFAULT_PERIOD,
  METER_CONF,
};

export default {
  USER_TYPE,
  DEVICE_TYPE,
  CURRENCY,
  CURRENCY_SYMBOL,
  MODEL_TYPE,
  WS_TYPE,
  WS_TYPE_TO_DATA_INTERFACE,
  SCHED_TYPE,
  AQ_QUALITY_VALUES,
  AQ_VENT_MODE_VALUES,
  AQ_COLORS,
  THIRD_PARTY_TYPES,
  DEVICE_TO_MODEL_TYPE,
  DEVICES_TYPES_TO_BROADCAST,
  DEVICES_NAME_EDITABLE,
  WS_CODE_TO_TYPE,
  VISUALIZATION,
  SEMANTIC_DEVICE_GROUP_TYPE,
  DEVICE_TO_SEMANTIC_TYPE,
  ALTHERMA,
  MODES,
  WEB_MODES,
  DEFAULT_BUTTONS_ORDER,
  DEFAULT_NUM_VISIBLE_BUTTONS,
  SCENES,
  PERIODS,
  AQ_MODE,
  VMC_MODE,
  TEMP_STEPS,
  TEMP,
  SLATS_MODE,
  SPEED_TYPE,
  ECO_TYPE,
  USERMODE,
  STAGES,
  ICONS,
  WIFI_STATUS,
  BLUETOOTH,
  COVERAGE,
  UTILS,
  SCHEDULES,
  TAI,
  INPUT_TYPE,
  INSTALLATION_SCHEDULE_TYPES,
  PAGINATION,
  PROTOCOL,
  AUX_HEAT,
  FALLBACK,
  STAGES_TYPE,
  STAGES_MODE,
  STAGES_NAME,
  AZ_AIR_TYPES_SS_MAP,
  AZ_RADIANTC_TYPES_SS_MAP,
  TIMERS,
  OUTPUTS_PARAMS_REGEX,
  WEBSERVER_AIRZONE_ICON,
  WEBSERVER_HARDWARE,
  GRADIENTS,
  GRAPHICS,
  COUNTRY_CODE,
  BUTTON_TESTS_AVAILABLES,
  PRICE_NOT_FOUND,
  FIXED_TARIFF,
  CUSTOM_TARIFF,
  REGULATED_TARIFF,
  COLOR_DEFAULT_PERIOD,
  METER_CONF,
};
