import UNIT_CONSTANTS from "Units/constant";
import CORE_CONSTANTS from "Core/constant";
import mapTranslations from "Units/utils/mapTranslations";

const CONSTANTS = {...UNIT_CONSTANTS, ...CORE_CONSTANTS}

/**
 * Convierte el tipo de dispositivo recibido en backend al tipo esperado en el modelo Device de Frontend
 *
 * @param {String} type - Tipo recibido desde el backend
 */

const getDeviceType = type => {
  return CONSTANTS.DEVICE_TO_MODEL_TYPE[type] || CONSTANTS.MODEL_TYPE.DEVICE;
};

const getTempInUnits = (temps, units = 'celsius') => {
  return temps[units];
};

const getSignalValue = (signal, unit = 'stat_quality') => {

    const parseSignal = parseInt(signal, 10);

    if(unit === 'stat_rssi'){
      switch (true) {
          case parseSignal < CONSTANTS.COVERAGE.LOW:
            return 1;
          case parseSignal < CONSTANTS.COVERAGE.MEDIUM:
            return 2;
          case parseSignal < CONSTANTS.COVERAGE.HIGH:
            return 3;
          default:
            return 4;
        }
    }

    return parseSignal;

}

const getAqStatusColor = state => {
  const colorMap = {
      good: CONSTANTS.AQ_COLORS.aq_good,
      regular: CONSTANTS.AQ_COLORS.aq_regular,
      bad: CONSTANTS.AQ_COLORS.aq_bad,
      night_mode: CONSTANTS.COLORS.APP.default
  };
  return colorMap[state];
}

const getAqStatusText = state => {
  if(state === undefined) return null;

  return typeof mapTranslations.AQ_QUALITY[state] === 'function' ? mapTranslations.AQ_QUALITY[state]() : '';
}

export { getDeviceType, getTempInUnits, getSignalValue, getAqStatusColor };
