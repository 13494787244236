import { Model } from '@vuex-orm/core';
import TariffService from 'Units/services/tariff.service';
import Period from './Period.model';
import Installation from './Installation.model';

export default class Tariff extends Model {
  static entity = 'tariffs';

  /** *************************************************************
   * CAMPOS
   *  ************************************************************** */
  static fields() {
    return {
      id: this.string(null).nullable(), // id del modelo para consultas
      name: this.string(undefined).nullable(), // nombre del modelo
      price: this.number(undefined).nullable(), // precio del modelo
      default_price: this.number(undefined).nullable(), // precio por defecto de la tarifa (Sólo se usa en las de tipo fijo)
      type: this.string(undefined).nullable(), // tipo de tarifa (fijo, regulada, custom, etc)
      description: this.string(undefined).nullable(), // descripción de la tarifa (si existe)
      currency: this.attr(undefined).nullable(), // moneda del precio
      periods: this.hasMany(Period, 'tariff_id'), // Periodos y franjas horarias
      active: this.boolean(undefined).nullable(), // si está activo o no
      regulated_tariff_id: this.string(undefined).nullable(), // id de la tarifa regulada
    };
  }
  /**
   * Devuelve la información sobre el precio eléctrico y tarifas en una zona horaria
   *
   * @param {String} installationID - Id de la instalación
   * @param {String} tarifData - Información de la tarifa
   * @return {Object} - Información sobre las tarifas y precios por horas
   */
  static saveTariff = async({installationID, params}) => {
    const responseData = await TariffService.saveTariff({installationID, params});
    const installation = Installation.find(installationID);
    const {price, ...restWidgets} = installation.widgets;


    Tariff.insertOrUpdate({
      where: responseData._id,
      data: responseData
    });

    Installation.insertOrUpdate({
      data: {
        id: installationID,
        widgets: {
          price: {
            currency: responseData?.currency?.code,
            tariff_id: responseData?.id ,
            tariff_type: responseData?.type,
            regulated_tariff_id: responseData?.regulated_tariff_id,
          },
          ...restWidgets
        }
      }
    });

    return responseData;
  }

}
