import StorageService from 'Core/services/storage.service';
import moment from 'moment'
import constant from 'Core/constant';
import User from 'Auth/models/User';
//max time to wait for rating dialog to display on iOS
const MAX_DIALOG_WAIT_TIME_IOS = 5*1000;

//max time to wait for rating dialog to display on Android and be submitted by User
const MAX_DIALOG_WAIT_TIME_ANDROID = 300*1000;

const platformId = constant.IS_MOBILE;

let ratingTimerId;

function ratingDialogNotShown(){
  let msg;
  if(platformId === constant.PLATFORM.ANDROID){
    msg = "Rating dialog outcome not received (after " + MAX_DIALOG_WAIT_TIME_ANDROID + "ms)";
  }else if(platformId === constant.PLATFORM.IOS){
    msg = "Rating dialog was not shown (after " + MAX_DIALOG_WAIT_TIME_IOS + "ms)";
  }
  // User.editUserMeta({
  //   data: {
  //     app_rating: {
  //       status: "timeout",
  //       msg,
  //       shown: false,
  //       platform: platformId
  //     }
  //   }
  // })
  console.warn(msg);
}

const ReviewService = {
  // Lanza el modal de valoración
  launchRating() {
    if(platformId === constant.PLATFORM.ANDROID){
      ratingTimerId = setTimeout(ratingDialogNotShown, MAX_DIALOG_WAIT_TIME_ANDROID);
    }

    LaunchReview.rating(function(status){
        console.log("Rating dialog status: " + status);
        if(status === "requested"){
          if(platformId === constant.PLATFORM.ANDROID){
              console.log("Displayed rating dialog");
              StorageService.setItem('lastReview', moment());
              User.editUserMeta({
                data: {
                  app_rating: {
                    // lastShown: moment().toISOString(),
                    status,
                    lastShown: null,
                    shown: true,
                    platform: platformId
                  }
                }
              })
              clearTimeout(ratingTimerId);
          }else if(platformId === constant.PLATFORM.IOS){
              console.log("Requested rating dialog");
              ratingTimerId = setTimeout(ratingDialogNotShown, MAX_DIALOG_WAIT_TIME_IOS);
            }
          }else if(status === "shown"){
            StorageService.setItem('lastReview', moment());
            User.editUserMeta({
              data: {
                app_rating: {
                  lastShown: moment().toISOString(),
                  shown: true,
                  platform: platformId,
                  status
                }
              }
            })
            console.log("Rating dialog displayed");
            clearTimeout(ratingTimerId);
        }else if(status === "dismissed"){
            User.editUserMeta({
              data: {
                app_rating: {
                  status,
                  shown: true,
                  platform: platformId,
                  lastShown: null
                }
              }
            })
            console.log("Rating dialog dismissed");
            clearTimeout(ratingTimerId);
        }
    }, function (err){
        console.error("Error launching rating dialog: " + err);
        clearTimeout(ratingTimerId);
    });
  },
  // Te dirige a la store para la valoración de la app
  launchReview(){
    console.log('launchReview');
    LaunchReview.launch(
      success => {
        console.log("Success in launch review", success)
      },
      error => {
        console.log("Error in launch review", error)
      }
    )
  },
  // Comprueba si el dispositivo soporta las valoraciones
  isRatingSupported(){
    const isRatingSupported = LaunchReview.isRatingSupported()
    console.log("is Rattings Supported", isRatingSupported)
    return isRatingSupported;
  },

}

export default ReviewService;
