import { mounted, unmounted } from 'vue';

export const longpress = {

  mounted(el, binding) {
    let pressTimer = null;
    let startX = 0;
    let startY = 0;
    const moveThreshold = 10; // Umbral de desplazamiento en píxeles

    const start = (e) => {
      if (e.type === 'click' && e.button !== 0) return;

      const { handler, delay = 600 } = binding.value;

      // Capturar las coordenadas iniciales del toque
      if (e.touches) {
        startX = e?.touches?.[0].clientX;
        startY = e?.touches?.[0].clientY;
      }


      if (pressTimer === null) {
        pressTimer = setTimeout(() => {
          if (typeof handler === 'function') {
            handler();
          } else {
            binding.value(e);
          }
        }, delay);
      }
    };

    const cancel = (e) => {
      if (pressTimer !== null) {
        clearTimeout(pressTimer);
        pressTimer = null;
      }
    };

    // Detectar desplazamiento táctil y cancelar el longpress si supera el umbral
    const touchMove = (e) => {
      const touch = e.touches[0];
      const deltaX = Math.abs(touch.clientX - startX);
      const deltaY = Math.abs(touch.clientY - startY);

      if (deltaX > moveThreshold || deltaY > moveThreshold) {
        cancel();
      }
    };

    const preventContextMenu = (e) => {
      e.preventDefault();
    }

    const move = (e) => {
      const deltaX = Math.abs(e.clientX - startX);
      const deltaY = Math.abs(e.clientY - startY);

      if (deltaX > moveThreshold || deltaY > moveThreshold) {
        cancel();
      }
    };

    el.addEventListener('mousedown', start);
    el.addEventListener('touchstart', start);
    el.addEventListener('click', cancel);
    el.addEventListener('mouseout', cancel);
    el.addEventListener('touchend', cancel);
    el.addEventListener('touchcancel', cancel);
    el.addEventListener('mousemove', move);
    el.addEventListener('contextmenu', preventContextMenu); // Previene el menú contextual en pulsaciones largas
    el.addEventListener('touchmove', touchMove); // Detectar desplazamiento táctil

    el._longpressHandlers = { start, cancel, move, touchMove };
  },

  unmounted(el) {
    const { start, cancel, move, touchMove, preventContextMenu } = el._longpressHandlers || {};

    if(preventContextMenu) {
      el.removeEventListener('contextmenu', preventContextMenu);
    }

    if (start) {
      el.removeEventListener('mousedown', start);
      el.removeEventListener('touchstart', start);
    }
    if (cancel) {
      el.removeEventListener('click', cancel);
      el.removeEventListener('mouseout', cancel);
      el.removeEventListener('touchend', cancel);
      el.removeEventListener('touchcancel', cancel);
    }
    if (move) {
      el.removeEventListener('mousemove', touchMove);
    }
    if (touchMove) {
      el.removeEventListener('touchmove', touchMove);
    }

    delete el._longpressHandlers;
  }
};
