import { mounted, unmounted } from 'vue';

export const swipe = {
  mounted(el, binding) {
    let startX = 0;
    let startY = 0;
    let startTime = 0;
    const threshold = binding.value?.threshold || 50; // Distancia mínima en px para considerar un swipe
    const restraint = 30; // Tolerancia vertical para evitar movimientos diagonales accidentales
    const allowedTime = 500; // Tiempo máximo para considerar un swipe válido

    const touchStart = (e) => {
      const touch = e.changedTouches[0];
      startX = touch.pageX;
      startY = touch.pageY;
      startTime = new Date().getTime();
    };

    const touchEnd = (e) => {
      const touch = e.changedTouches[0];
      const distX = touch.pageX - startX;
      const distY = touch.pageY - startY;
      const elapsedTime = new Date().getTime() - startTime;

      if (elapsedTime <= allowedTime && Math.abs(distY) <= restraint) {
        if (distX > threshold) {
          // Deslizó hacia la derecha
          binding.value?.onSwipeRight?.(e);
        } else if (distX < -threshold) {
          // Deslizó hacia la izquierda
          binding.value?.onSwipeLeft?.(e);
        }
      }
    };

    el.addEventListener('touchstart', touchStart, { passive: true });
    el.addEventListener('touchend', touchEnd);

    el._swipeHandlers = { touchStart, touchEnd };
  },

  unmounted(el) {
    const { touchStart, touchEnd } = el._swipeHandlers || {};
    if (touchStart && touchEnd) {
      el.removeEventListener('touchstart', touchStart);
      el.removeEventListener('touchend', touchEnd);
    }
    delete el._swipeHandlers;
  }
};
