/* eslint-disable camelcase */
/**
* @typedef {import('Units/models/DeviceHierarchy').Device} Device
* @typedef {import('Units/models/Installation.model').Installation} Installation
*/

import CONSTANTS from 'Units/constant';
import { hasProp } from 'Core/utils/validate.utils';
import i18n from 'Core/services/language.service';
import { getModeStringValue } from 'Units/utils/mode.utils';
import { getDeviceType } from 'Units/utils/device.utils';
import { getActualDate, getCompleteHourlyDay } from 'Units/utils/weatherUtils';
import moment from 'moment-timezone';
// import lodash from 'lodash';
import { getAlias } from 'Units/utils/utils';
import store from 'Core/store/store';


/**
 * Función que comprueba si existía conexión en una fecha determinada dada. Recibe la fecha a evaluar y un array de datos de conexión.
 *
 * @param {String} dt - Date en ISOString. Fecha a evaluar para comprobar si estaba en conexión
 * @param {Array<Object>} connectionData - Datos de cambios de conexión {dt: ISOSTring, value: boolean}
 * @returns boolean Estado de conexión de la fecha de entrada indicada.
 */
// function isConnected(dt, connectionData) {

//   if(connectionData) {
//     for(let i = 0; i < connectionData.length - 1; i++) {
//       // console.log("IS_CONNECTED", dt, connectionData[i].dt)
//       if(connectionData[i].dt <= dt && dt < connectionData[i + 1].dt) {
//         return connectionData[i].value
//       }
//     }

//     return connectionData[connectionData.length - 1].value;
//   }

//   return false;
// }

/**
 * Suprime datos con valores "false" de una colección si no se cumple un mínimo rango de fecha (en segundos) entre esa muestra y la siguiente
 * NOTA: Usado principalmente para obviar cortes de conexión si no son, al menos, de una duración determinada
 *
 * @param {Array} collectionData - colección de datos a evaluar
 * @param {number} range - Dieferencia de tiempo (en segundos) necesaria para considerar la muestra
 */
function smoothConnectionData(collectionData, range = 0) {
  const filterData = []


  if(collectionData) {
    for(let i = 0; i < collectionData.length; i++) {
      if(i < collectionData.length - 1 && collectionData[i].value === false) {
        // Obtengo las fechas en segundos para comparar
        const nextDate = moment(collectionData[i + 1].dt).unix();
        const date = moment(collectionData[i].dt).unix();

        if(nextDate - date > range) {
          filterData.push(collectionData[i]);
        }

      } else {
        filterData.push(collectionData[i]);
      }
    }
  }

  return filterData
}

/**
 * Filtra los datos dejando entre muestras un espacio en tiempo mínimo igual a "range"
 *
 * @param {Array} collectionData - colección de datos a evaluar
 * @param {number} range - Dieferencia de tiempo (en segundos) necesaria para considerar la muestra
 */
// function smoothFilterData(collectionData, range = 0) {

//   if(range === 0) return collectionData; // Si no hay range no vamos a realizar ninguna operación, devolvemos la colección

//   const filterData = [];

//   if(collectionData && collectionData.length > 0) {

//     // Incluímos la primera muestra de la colección
//     filterData.push(collectionData[0])

//     // Obtengo la fecha de la primera muestra para comparar (en segundos)
//     let actualSampleDate = moment(collectionData[0].dt).unix();
//     let actualSampleValue = collectionData[0].value; // OJO: puede ser un object!

//     for(let i = 1; i < collectionData.length; i++) {
//       if(i < collectionData.length - 1) {
//         // Obtengo las fecha de la muestra siguiente en segundos para comparar
//         const nextSampleDate = moment(collectionData[i].dt).unix();
//         // comparamos la fecha de la pos actual con la fecha anterior, si supera el rango agregamos la muestra
//         // NOTA: SÓLO agregamos la muestra si hay cambios. Si el valor es un object habrá que tenerlo en cuenta.
//         if((nextSampleDate - actualSampleDate >= range ) && (!lodash.isEqual(collectionData[i].value, actualSampleValue))) {
//           actualSampleDate = nextSampleDate;
//           actualSampleValue = collectionData[i].value;
//           filterData.push(collectionData[i]);
//         }
//       } else {
//         // Agregamos la última muestra
//         filterData.push(collectionData[i])
//       }
//     }
//   }

//   return filterData;
// }

function getValueBetween(dt, compareToData) {
  let value = false;
  for(let i = 0; i < compareToData.length - 1; i++) {

    if(compareToData[i].dt <= dt && dt <= compareToData[i + 1].dt) {
      value = compareToData[i].value
      break;
    }
  }


  return value;
}

function addEndDateData(collectionData, timeZone) {
  if(!Array.isArray(collectionData)) return;
  if(collectionData.length === 0) return;

  const actualDate = `${ moment().tz(timeZone).toISOString().split('.')[0].slice(0,-2) }00.000Z`;


  // Agregamos una muestra más al final del día con el último valor del modo registrado
  const endDate = moment(collectionData[0].dt).add(24, 'hour').toISOString();

  if(endDate > actualDate && collectionData[collectionData.length - 1].dt <= actualDate) {
    collectionData.push({
      ...collectionData[collectionData.length - 1],
      dt: actualDate
    })

    return;
  }

  if(collectionData[collectionData.length - 1].dt < endDate) {
    collectionData.push({
      ...collectionData[collectionData.length - 1],
      dt: endDate
    })
  }
}

function addPreviusValueData(dt, collectionData) {
  if(!Array.isArray(collectionData)) return;
  if(collectionData.length === 0) return;

  for(let i = 0; i < collectionData.length - 1; i++) {

    if(collectionData[i].dt >= dt) {

      if (collectionData[i].dt === dt ) break;

      const data = {
        dt,
        value: collectionData[i].value
      }
      collectionData.splice(i, 0, data)
      break;
    }
  }
}


function fillData(inputsData, precision, timeZone, fillExtraData) {

  if(!inputsData) return [];
  if(inputsData.length === 0) return [];

  const outputsData = [];

  const actualDate =  moment().tz(timeZone).toISOString();


  let nextDate = moment(inputsData[0].dt);

  for(let i = 0; i < inputsData.length - 1; i++) {
    if(nextDate.toISOString() > actualDate) break;

    let isFillData = false;

    while(inputsData[i].dt <= nextDate.toISOString() && nextDate.toISOString() < inputsData[i + 1].dt && nextDate.toISOString() <= actualDate) {

      if(fillExtraData && isFillData) {
        outputsData.push({
          ...inputsData[i],
          ...fillExtraData,
          dt: nextDate.toISOString(),
        });

      } else {
        isFillData = true;

        outputsData.push({
          ...inputsData[i],
          dt: nextDate.toISOString(),
          // value: inputsData[i].value,
        })

      }

      nextDate = nextDate.add(precision, 'minutes');
    }

  }


  addEndDateData(outputsData, timeZone);

  return outputsData
}

// function getModeInTime(dt, modeData) {
//   if(modeData) {
//     for(let i = 0; i < modeData.length - 1; i++) {
//       if(modeData[i].dt <= dt && dt < modeData[i + 1].dt) {
//         return modeData[i].value;
//       }
//     }

//     return modeData[modeData.length - 1].value;
//   }

//   return false;
// }


const cloud2web = {

  getInstallationData: (installationID, response, locationData, user, weatherData) => {
    const groups = [];
    const webservers = {};
    const devices = [];
    const commands = [];
    const scenes = [];

    let positionGroup = 0;
    //
    // Cargamos en el data de installation el tipo de acceso de usuario
    //

    const installation = {
      color: response?.data?.color ? response.data.color : 1,
    };

    if(hasProp(response?.data, 'name')){
      installation.name = response?.data?.name;
    }
    if(hasProp(response?.data, 'icon')){
      installation.icon = response?.data?.icon;
    }
    if(hasProp(response?.data, 'location_id')){
      installation.locationID = response?.data?.location_id;
    }

    if(hasProp(response?.data, 'added_at')){
      installation.added_at = response?.data?.added_at;
    }

    if(hasProp(response?.data, 'confirmed_date')){
      installation.confirmed_date = response?.data?.confirmed_date;
    }

    if(user && hasProp(response?.data, 'access_type')) {
      installation.access_type = user?.admin_mode === true ? CONSTANTS.USER_TYPE.ADMIN : response.data.access_type;
    }

    if(locationData) {
      installation.timezone = locationData.timezone;
      installation.countryCode = locationData.countryCode;
      installation.city = locationData.city;
      installation.country = locationData.country;
      installation.coords = locationData.coords;
      installation.priceRegion = locationData.priceRegion;
    }

    if(weatherData) {
      installation.weather = weatherData;
    }
    //
    // Cargamos la información de las scenas
    //
    if(hasProp(response?.data, 'scenes') && Array.isArray(response.data.scenes) && response.data.scenes.length > 0) {
      response.data.scenes.forEach((scene, index) => {
        const newScene = {}

        if(scene.meta !== undefined) {
          newScene.id = scene._id;
          newScene.installation_id = installationID;
          newScene.name = scene.meta.name ? scene.meta.name : '';
          newScene.icon = scene.meta.icon ? scene.meta.icon : undefined;
          newScene.total_time = scene.meta.total_time ? scene.meta.total_time : undefined;
        }
        if(scene.status !== undefined) {
          newScene.running = scene.status.running;
          newScene.start_time = scene.status.start_time ? scene.status.start_time : undefined
        }

        newScene.position = index;

        scenes.push(newScene);
      });

    }
      //
      // Cargamos las ids de webservers que disponen de programaciones de Calendario o Semanal
      //
      if(hasProp(response?.data, 'plugins') && hasProp(response.data.plugins, 'schedules')){
        installation.schedulesWeek_ws_ids = response?.data?.plugins?.schedules?.week_ws_ids;
        installation.schedulesCalendar_ws_ids = response?.data?.plugins?.schedules?.calendar_ws_ids;
        installation.schedulesAcs_ws_ids = response?.data?.plugins?.schedules?.acs_ws_ids;
        installation.schedulesVmc_ws_ids = response?.data?.plugins?.schedules?.vmc_ws_ids;
        installation.schedulesRelay_ws_ids = response?.data?.plugins?.schedules?.relay_ws_ids;
        installation.schedulesActivated = response?.data?.plugins?.schedules?.activated;
      }

      //
      if(hasProp(response?.data, 'plugins') && hasProp(response.data.plugins, 'widgets')){
        installation.widgets = response.data.plugins.widgets;
      }

      if (hasProp(response?.data, 'groups')) {
        const groupsData = response.data.groups;
        let groupNumber = 0; // contador de grupos que disponen de zonas para asignar nombre por defecto

        groupsData.forEach(group => {
          let renderGroup = false; // Flag para controlar si incrimentaremos el groupNumber para mostrar en la vista
          //
          // Obtengo los dispositivos de cada grupo y los parseo al modelo esperado
          //
          const countersDeviceType = {};
          group.devices.forEach((device, index) => {
            if(!countersDeviceType[CONSTANTS.DEVICE_TO_SEMANTIC_TYPE[device.type]]) countersDeviceType[CONSTANTS.DEVICE_TO_SEMANTIC_TYPE[device.type]] = 0;

            const deviceData = {
              id: device?.device_id,
              device_type: device?.type,
              device_semantic_type: device?.type ? CONSTANTS.DEVICE_TO_SEMANTIC_TYPE[device.type] : undefined,
              installation_id: installationID,
              webserver_id: device?.ws_id,
              system_number: device?.meta.system_number,
              group_id: group?.group_id,
              zone_number: device?.meta.zone_number,
              position: index,
              num_visible_buttons: device?.config?.num_visible_buttons || CONSTANTS.DEFAULT_NUM_VISIBLE_BUTTONS
            }
            //
            // Lógica para calcular el orden de los iconos de menú en la vista zona
            //
            if (device?.config?.buttons_order) {
              const buttonsOrder = device.config.buttons_order;
              let defaultButtons = CONSTANTS.DEFAULT_BUTTONS_ORDER;

              buttonsOrder.forEach(item => {
                defaultButtons = defaultButtons.filter(defaultItem => defaultItem !== item);
              });

              deviceData.buttons_order = [...buttonsOrder, ...defaultButtons];
            } else {
              deviceData.buttons_order = CONSTANTS.DEFAULT_BUTTONS_ORDER;
            }

            if (device?.meta.ext_compatible) {
              deviceData.third_party_compatible = device.meta.ext_compatible
            }

            if (device?.meta.ext_link){
              if (device?.meta?.ext_link.ext_device_id) {
                deviceData.third_party_id = device.meta.ext_link.ext_device_id
              }

              if (device?.meta?.ext_link.integration) {
                deviceData.third_party_type = device.meta.ext_link.integration
              }

              if (device?.meta?.ext_link.meta && device?.meta?.ext_link?.meta?.name) {
                deviceData.third_party_name = device.meta.ext_link.meta.name
              }
            }

            if(device?.type) {
              deviceData.type = CONSTANTS.DEVICE_TO_MODEL_TYPE[device.type] || CONSTANTS.MODEL_TYPE.DEVICE;

              if(device.name) {
                deviceData.name = device.name;
              } else {
                deviceData.isDefaultName = true;
              }

              deviceData.name_editable = CONSTANTS.DEVICES_NAME_EDITABLE.includes(device.type) || false;

              switch (device.type) {
                case CONSTANTS.DEVICE_TYPE.az_system:
                  // deviceData.name = device.name ? device.name : `${i18n.t('system')} ${++positionSystems}`;

                  break;
                case CONSTANTS.DEVICE_TYPE.aidoo:
                case CONSTANTS.DEVICE_TYPE.aidoo_it:
                case CONSTANTS.DEVICE_TYPE.az_airqsensor:
                case CONSTANTS.DEVICE_TYPE.az_energy_clamp:
                  // Si el grupo tiene alguna zona se va a renderizar en la vista de zonas
                  renderGroup = true;

                  break;
                case CONSTANTS.DEVICE_TYPE.az_zone:
                  // deviceData.name = device.name ? device.name : `${i18n.t('installations.zone')} ${++positionZones}`;
                  deviceData.zone_number = device.meta.zone_number;
                  // Si el grupo tiene alguna zona se va a renderizar en la vista de zonas
                  renderGroup = true;
                  break;


                default:

              }
            }

            devices.push(deviceData);

            if (!hasProp(webservers, device.ws_id)) {
              webservers[device.ws_id] = {
                id: device.ws_id,
                installation_id: installationID,
              };
            }

          });


          // Si al menos tiene una zona (o aidoo) el grupo se va a renderizar
          // Incrementamos el contador para el nombre asignado por defecto al grupo
          if(renderGroup){
            groupNumber++;
          }

          groups.push({
            id: group.group_id,
            name: group.name ? group.name : `${i18n.global.t('installations.group')} ${groupNumber}`,
            installation_id: installationID,
            position: positionGroup++,
          });
        });
      }

      return {
        data: installation,
        devices,
        groups,
        webservers,
        scenes,
        commands
      };


  },

  getSceneData: (scene, units = 'celsius') => {
    // const scene = response.data;

    const newScene = {};
    const commands = [];

    if(scene.meta !== undefined) {
      newScene.id = scene?._id;
      newScene.installation_id = scene?.installation_id;
      newScene.name = scene?.meta?.name ? scene.meta.name : '';
      newScene.icon = scene?.meta?.icon ? scene.meta.icon : undefined;
      newScene.total_time = scene?.meta.total_time ? scene.meta.total_time : undefined;
    } else {
      // Comprobamos si es demo y recibimos directamente name e icon
      newScene.id = `scene${Math.trunc(Math.random() * 1000000)}`;
      newScene.name = scene.name ? scene.name : '';
      newScene.icon = scene.icon ? scene.icon : 1 ;
    }

    if(scene.status !== undefined) {
      newScene.running = scene.status.running;
      newScene.start_time = scene.status.start_time ? scene.status.start_time : undefined
    }

    if(scene.commands !== undefined &&
      Array.isArray(scene.commands) &&
      scene.commands.length > 0) {
      scene.commands.forEach((item, pos) => {
        const command = {}
        command.scene_id = scene._id;
        command.type = item.type;
        command.target = item.target;
        // command.actions = item.actions;
        command.position = pos
        // Al agregar las acciones comprobamos que si es de tipo setpoint parseamos
        // el object con su valor en celsius o fah correspondiente
        if(item.actions && Object.keys(item.actions).length > 0) {
          const actions = {}
          Object.keys(item.actions).forEach(key => {
            switch(key) {
              case 'setpoint':
                actions.setpoint = item.actions[key][units];
                break;
              default:
                actions[key] = item.actions[key]
            }
          });
          command.actions = actions;
        }
        commands.push(command);
      });
    }

    return {
      scene: newScene,
      commands
    }

  },

  //TODO: Eliminar este método cuando se sustituya en todo el código por el de getWebserverData (habría que unificarlos)
  getWebserverBLEData: async (webserverID, wwssData) => {

    return new Promise(resolve => {
      // Inicializo el data para parsear al formato que espero en el modelo
      const data = {
        id: webserverID,
        name: wwssData?.config?.bt_name,
        mac: wwssData?.config?.mac,
        ota: wwssData?.config?.ota,
        pin: wwssData?.config?.pin,
        server_sched_active: wwssData?.config?.server_sched_active,
        stat_ap_mac: wwssData?.config?.stat_ap_mac,
        stat_channel: wwssData?.config?.stat_channel,
        firmware: wwssData?.config?.ws_fw,
        units: wwssData?.config?.units,
        connection_date: wwssData?.status?.connection_date,
        disconnection_date: wwssData?.status?.disconnection_date,
        isConnected: wwssData?.status?.isConnected,
        stat_quality: wwssData?.status?.stat_quality,
        local_ipv4: wwssData?.status?.local_ipv4,
        stat_rssi: wwssData?.status?.stat_rssi ? wwssData.status.stat_rssi : undefined,
        stat_ssid: wwssData?.config?.stat_ssid ? wwssData.config.stat_ssid : undefined,
        ws_sched_available: wwssData?.config?.ws_sched_available,
        ws_hw: wwssData?.config?.ws_hw,
        type: wwssData?.ws_type,
        meter_conf: wwssData?.config?.meter_conf,
        meter_conf_values: wwssData?.config?.meter_conf_values,
      }
      if(wwssData?.config?.conn_type === 'wifi') {
        data.isWifi = true;
      }

      if(wwssData?.config?.conn_type === 'eth') {
        data.isEthernet = true;
      }
      // Si no viene el alias, ni la mac no actualizo alias
      // Fix: Dispositivo_undefined
      if (wwssData?.config?.alias || wwssData?.config?.mac) {
        data.alias = getAlias(wwssData?.config?.alias, wwssData?.config?.mac)
      }

      Object.keys(data).forEach(key => {
        if(data[key] === undefined || data[key] === null)
        {
          delete data[key];
        }

      })

      resolve({
        data,
      })

    })


  },

  getWebserverData: async ({installationID, webserverID, wwssData, devices, isBle = false}) => {

    return new Promise(resolve => {

      //
      // Inicializo el data para parsear al formato que espero en el modelo
      //

      // console.log("data", wwssData);

      const data = {
        id: webserverID,
        installation_id: installationID,
        mac: wwssData?.config?.mac,
        ota: wwssData?.config?.ota,
        pin: wwssData?.config?.pin,
        server_sched_active: wwssData?.config?.server_sched_active,
        stat_ap_mac: wwssData?.config?.stat_ap_mac,
        stat_channel: wwssData?.config?.stat_channel,
        firmware: wwssData?.config?.ws_fw,
        units: wwssData?.config?.units,
        connection_date: wwssData?.status?.connection_date,
        disconnection_date: wwssData?.status?.disconnection_date,
        isConnected: wwssData?.status?.isConnected,
        stat_quality: wwssData?.status?.stat_quality,
        local_ipv4: wwssData?.status?.local_ipv4,
        stat_rssi: wwssData?.status?.stat_rssi ? wwssData.status.stat_rssi : undefined,
        stat_ssid: wwssData?.config?.stat_safessid? wwssData?.config?.stat_safessid : wwssData?.config?.stat_ssid ? wwssData.config.stat_ssid : undefined,
        ws_sched_available: wwssData?.config?.ws_sched_available,
        ws_hw: wwssData?.config?.ws_hw,
        type: wwssData?.ws_type,
        lmachine: wwssData?.config?.lmachine_fw,
        aidoo_group: wwssData?.config?.wsmmgroup,
        aidooDevices: wwssData?.devices,
        meter_conf: wwssData?.config?.meter_conf,
        meter_conf_values: wwssData?.config?.meter_conf_values,
        debug_sshd_active: wwssData?.config?.debug_sshd_active,
        debug_serial_tty_active: wwssData?.config?.debug_serial_tty_active,
        serialnum_mainb: wwssData?.config?.serialnum_mainb
      }

      // Estos datos sólo se añaden si no es un dispositivo BLE. isWifi e isEthernet lo obtenemos en la petición info ble.
      if(!isBle && wwssData?.config?.conn_type) {
        data.isWifi = wwssData?.config?.conn_type === 'wifi';
        data.isEthernet = wwssData?.config?.conn_type === 'eth';
      }

      if(wwssData?.config?.stat_safessid) {
        store.dispatch('setSsidSafeFormat', true);
      }

      // Si no viene el alias, ni la mac no actualizo alias
      // Fix: Dispositivo_undefined
      if (wwssData?.config?.alias || wwssData?.config?.mac) {
        data.alias = getAlias(wwssData?.config?.alias, wwssData?.config?.mac)
      }

      Object.keys(data).forEach(key => {
        if(data[key] === undefined || data[key] === null)
        {
          delete data[key];
        }

      })

      const devicesData = [];

      if(devices){
        const countersDeviceType = {};
        wwssData.devices?.forEach( device => {

          if(!countersDeviceType[CONSTANTS.DEVICE_TO_SEMANTIC_TYPE[device.type]]) countersDeviceType[CONSTANTS.DEVICE_TO_SEMANTIC_TYPE[device.type]] = 0;

          const deviceData = {
            id: device.device_id,
            type: getDeviceType(device.device_type),
            position: countersDeviceType[CONSTANTS.DEVICE_TO_SEMANTIC_TYPE[device.type]]++,
            installation_id: installationID,
            system_number: (typeof device.config !== 'undefined') ? device.config.system_number : null,
            new_virtual_zones_allowed: device.config.new_virtual_zones_allowed > 0 ? device.config.new_virtual_zones_allowed : null,
            webserver_id: webserverID,
            zone_number: (typeof device.config !== 'undefined') ? device.config.zone_number : null,
            device_type: device.device_type,
            device_semantic_type: device.device_type ? CONSTANTS.DEVICE_TO_SEMANTIC_TYPE[device.device_type] : undefined,
            isConnected: device.isConnected,
            name_editable: device.device_type ? CONSTANTS.DEVICES_NAME_EDITABLE.includes(device.device_type) : false,
            ec_fw: (typeof device.config !== 'undefined') ? device.config.ec_fw : null,
          }

          if(device.name) {
            deviceData.name = device.name;
          } else {
            deviceData.isDefaultName = true;
          }

          // Clean undefined values from object deviceData
          Object.keys(deviceData).forEach((key) => deviceData[key] === undefined && delete deviceData[key]);

          devicesData.push(deviceData);
        });
      }

      resolve({
        data,
        devicesData
      })

    })


  },

  getSchedules: (schedules, userUnits) => {

    const data = [];

    schedules.forEach( sched => {

      const startConf = {}

      if(hasProp(sched,'start_conf')){
        Object.keys(sched.start_conf).forEach( param => {
          //
          // Si es parámetro de temperatura
          //

          if(hasProp(sched.start_conf[param], 'celsius')){
            startConf[param] = sched.start_conf[param][userUnits];
          } else {
            startConf[param] = sched.start_conf[param];
          }
        });
      }
      const modelSched = {
        id: sched._id,
        device_ids: sched.device_ids,
        type: sched.type,
        isCalendarSched: sched.isCalendarSched,
        prog_enabled: sched.prog_enabled,
        start_conf: startConf,
        name: sched.name,
        units: userUnits
      }
      data.push(modelSched);
    })

    return data;
  },

  /**
   * Recibe la respuesta de la petición de "prices" relativas a las tarifas y
   * devuelve un objeto con la información a representar del precio actual, el precio
   * máx y mín del día y el array de valores de todo el día
   *
   * @param {Object} responseData - Datos de respuesta del backend a transformar
   * @param {String} timezone - Zona horaria de la instalación
   * @param {String} region - Región de la instalación
   * @param {String} actualDay - Día del precio electrico
   * @returns {Object} Datos mapeados para la interfaz web
   */
  getPriceData: (responseData, timeZone, region, actualDay = true) => {
    // Obtengo desde la fecha UTC la fecha local correspondiente al timezone de la instalación
    const updatedAt = (new Date(responseData.price.updated_at))
      .toLocaleTimeString('es-ES', {timeZone});

    const ratesName = responseData?.price.name || 'price Name';
    const maxPrice = responseData?.price.max || {value: 0.23505, dt: "2024-07-16T19:00:00.000Z"};
    const minPrice = responseData?.price.min || {value: 0.23505, dt: "2024-07-16T19:00:00.000Z"};
    const hasNext = responseData?.next;
    const hasPrev = responseData?.prev;
    const prices = responseData?.price.values;
    const timezone = responseData?.price.timezone;
    const currency = responseData?.price.currency;
    const period_legend = responseData?.period_legend;
    const actual = new Date();
    let actualPrice;
    let actualPeriod;

    // Hora actual con moment
    const now = timezone ? moment().tz(timezone).hour() : moment().hour()

    if(actualDay){
      // Si tenemos region, se asigna el precio de la hora UTC (coincide con el índice)
      if (region) {
        actualPrice = prices[now]
      } else {
        for(let i = 0; i < prices.length; i++) {
          const dateValue = new Date(prices[i].dt); // Fecha en formato UTC
          if(dateValue.getUTCHours() === actual.getUTCHours()) {
            actualPrice = prices[i];
            break;
          }
        }
      }

      if(actualPrice.period_id && typeof period_legend === 'object') {
        actualPeriod = period_legend[actualPrice.period_id];
      }
    }


    return {
      date: actual,
      updatedAt,
      currency,
      actualPrice,
      maxPrice,
      minPrice,
      timezone,
      prices,
      period_legend,
      actualPeriod,
      ratesName,
      hasNext,
      hasPrev
    }
  },

  getWeatherData: responseData => {

    const timezone = responseData.meta.timezone;
    const hourly = responseData.hourly; // Clima cada hora
    const daily = responseData.daily; // Clima días semana
    const aq = responseData.aq !== undefined ? responseData.aq : undefined;
    const options = {
      hour12: false,
      timezone,
    };
    const siteActualDate = new Date().toLocaleString('es-ES',options).split(":")[0];

    // const actualDate = new Date();
    let actualHourlyDay;
    let actualWeather; // Tiempo de la hora actual
    let dailyWeather;

    // Obtengo la información del tiempo en la hora actual
    if(hourly !== undefined){
      actualHourlyDay = getCompleteHourlyDay(hourly, timezone);
      actualWeather = actualHourlyDay.actualHour;
    }
    if(aq !== undefined) {
      for(let i = 0; i < aq.length; i++) {
        const registryDate = new Date(aq[i].dt).toLocaleString('es-ES',options).split(":")[0]; // fecha de la posición del array
        if(registryDate === siteActualDate){
          actualWeather.aq = aq[i];
          break;
        }
      }
    }

    if(daily !== undefined){
      dailyWeather = getActualDate(daily, timezone);
    }

    actualWeather.city = responseData?.meta?.text?.city;

    const data = {
      actualWeather,
      actualHour: actualHourlyDay.actualHour,
      nextHours: actualHourlyDay.nextHours,
      dailyWeather,
      timezone,
    }

    if(aq) data.aq = aq;

    return data;

  },
  /**
   * Se encarga de preparar la salida final de los datos preprocesados para su representación web
   *
   * @param {Object} inputData - Datos de entrada preparados para formatear
   * @param {*} units - Unidades para las temperaturas
   * @param {*} lang - Código idioma
   * @returns
   */
  formattedWeatherData: (inputData, units, lang) => {
    const data = {
      dt: inputData.actualHour.dt,
      city: inputData?.actualWeather?.city ? inputData?.actualWeather?.city[lang] : null,
      pop: parseInt(inputData?.actualWeather.pop * 100, 10),
      humidity: inputData?.actualWeather?.humidity,
      temp: inputData?.actualWeather?.temp[units],
      maxTemp: inputData?.dailyWeather?.actualDay?.temp?.max[units],
      minTemp: inputData?.dailyWeather?.actualDay?.temp?.min[units],
      code: inputData?.actualWeather?.weather[0]?.id,
      sunriseToday: inputData?.dailyWeather?.actualDay?.sunrise,
      sunsetToday: inputData?.dailyWeather?.actualDay?.sunset,
      sunriseTomorrow: inputData?.dailyWeather?.nextDays[0]?.sunrise,
      sunsetTomorrow: inputData?.dailyWeather?.nextDays[0]?.sunset,
      aqi: inputData?.actualWeather?.aq ? inputData.actualWeather.aq.aqi : undefined,
      airComponents:inputData?.actualWeather?.aq ? inputData.actualWeather.aq.components : undefined,
      nextDays: inputData?.dailyWeather?.nextDays,
      nextHours: inputData?.nextHours,
      timeZone: inputData?.timezone
    }

    return data;
  },
  /**
   * Se encarga de preparar la salida final de los datos preprocesados para su representación web
   *
   * @param {Object} inputData - Datos de entrada preparados para formatear
   * @returns {Object}
   */
  formattedTariffData: (inputData) => {
    const periods = inputData.periods?.map(period => {
      return {
        id: period.period_id,
        color: period.color,
        name: period.name,
        price: period.price_kWh,
        tariff_id: period.tariff_id,
        timeSlots: period.time_slots
      }
    })

    const typesWithPrice = [CONSTANTS.FIXED_TARIFF, CONSTANTS.CUSTOM_TARIFF];

    const data = {
      active: inputData?.active,
      currency: inputData?.currency,
      description: inputData?.description,
      id: inputData?._id,
      installation_id: inputData?.installation_id,
      name: inputData?.name,
      periods: periods || [],
      regulated_tariff_id: inputData?.regulated_tariff_id,
      type: inputData?.type,
    }

    if(typesWithPrice.includes(inputData?.type)) {
      if(inputData?.type === CONSTANTS.CUSTOM_TARIFF) data.default_price = inputData?.default_price;
      if(inputData?.type === CONSTANTS.FIXED_TARIFF) data.price = inputData?.price_kWh;
    }

    return data;
  },

  updateButtonsOrder: inputData => {
    const devices = [];

    if(hasProp(inputData, 'groups')) {
      const groupsData = inputData.groups;

      groupsData.forEach(group => {
        if(hasProp(group, 'devices') && Array.isArray(group.devices)) {
          group.devices.forEach(device => {
            const deviceData = {
              id: device?.device_id,
              num_visible_buttons: device?.config?.num_visible_buttons || CONSTANTS.DEFAULT_NUM_VISIBLE_BUTTONS,
            }

            if (device?.config?.buttons_order) {
              const buttonsOrder = device.config.buttons_order;
              let defaultButtons = CONSTANTS.DEFAULT_BUTTONS_ORDER;

              buttonsOrder.forEach(item => {
                defaultButtons = defaultButtons.filter(defaultItem => defaultItem !== item);
              });

              deviceData.buttons_order = [...buttonsOrder, ...defaultButtons];
            } else {
              deviceData.buttons_order = CONSTANTS.DEFAULT_BUTTONS_ORDER;
            }

            devices.push(deviceData);
          })
        }
      })
    }

    return devices;
  },

  /**
   * Recibe los datos formateados por DataInterface (hasta parámetros cloud)
   * Devuelve los datos formateados para nuestro modelo Device
   *
   * @param {Object} receiveData
   * @param {String} userUnits
   * @returns
   */
  parseData: (receiveData, userUnits, stringMode = true)=> {
    const data = {};
    const outputs = [];

    Object.keys(receiveData).forEach( param => {
      if(receiveData[param] !== undefined){
        if(receiveData[param] === null) {
          // el valor null es un valor válido, que implica quitar de la vista
          data[param] = receiveData[param];

        } else
        //
        // Comprobamos si el parámetro coincide con una representación de tipo "output" para el modelo "az_outputs"
        //
        if(param.match(CONSTANTS.OUTPUTS_PARAMS_REGEX)){
          outputs.push(receiveData[param]);
        } else
        //
        // Comprobamos si el parámetro es de temp para guardar el valor según unidad del usuario
        //
        if(hasProp(receiveData[param], 'celsius')){
          data[param] = receiveData[param][userUnits]
        //
        // Tratamiento del modo para parsear a nuestro modelo
        //
        } else if( param === 'mode' && stringMode === true) {
          const deviceType = receiveData.device_type;
          data.mode = getModeStringValue(receiveData[param], deviceType);
        } else if( param === 'mode_available' && stringMode === true){
          const deviceType = receiveData.device_type;
          data.mode_available = receiveData.mode_available.map( modeID => getModeStringValue(modeID, deviceType))
        //
        // Si el parámetro contiene un array comprobamos si es o no de temp y lo guardamos en consecuencia
        //
        } else if( Array.isArray(receiveData[param]) && receiveData[param].length > 0){
          const arrayData = [];
          receiveData[param].forEach( value => {
            if(hasProp(value, 'celsius')) {
              arrayData.push(value[userUnits])
            } else {
              arrayData.push(value);
            }
          })
          data[param] = arrayData;
        } else {
          data[param] = receiveData[param];
        }
      }
    });

    if(outputs.length > 0) data.outputs = outputs;

    return data;
  },

  getGraphComfortData: (receiveData, timeZone) => {


    const response = {};
    const actualDateISOStr = `${ moment().tz(timeZone).toISOString().split('.')[0].slice(0, -2) }00.000Z`;

    if(receiveData.params) {

      // Extraemos los parámetros recibidos
      const receiveParams = receiveData.params;

      // No tendremos en cuenta las desconexiones que sean de menos de 30 minutos
      const isConnected = smoothConnectionData(receiveParams.isConnected, 60 * 30);
      const power = receiveParams.power;
      // const active = smoothFilterData(receiveParams.active, CONSTANTS.GRAPHICS.SMOOTH_FILTER_RANGE.active); // Suavizamos separando al menos los cambios de valores un mínimo de 5 minutos
      const active = receiveParams.active // Sin aplicar filtro suavizado (active es un array que puede llegar a saturar en datos las gráficas)
      const auto_mode = receiveParams.auto_mode;
      const double_sp = receiveParams.double_sp;
      const is_tai_conf = receiveParams.is_tai_conf;
      const zone_work_temp = receiveParams.zone_work_temp;
      const tai_temp = receiveParams.tai_temp;
      const tank_temp = receiveParams.tank_temp;

      if(power) {
         // Agregamos una muestra más al final del día con el último valor del modo registrado
         addEndDateData(power, timeZone)
      }

      if(isConnected) {
         // Agregamos una muestra más al final del día con el último valor del modo registrado
         addEndDateData(isConnected,timeZone);
      }

      if(active) {
        // Agregamos una muestra más al final del día con el último valor del modo registrado
        addEndDateData(active, timeZone);
      }

      if(auto_mode && auto_mode.length > 0){
        // Agregamos una muestra más al final del día con el último valor del modo registrado
        addEndDateData(auto_mode, timeZone);
      }

      if(double_sp && double_sp.length > 0) {
        addEndDateData(double_sp, timeZone);
      }

      if(zone_work_temp && zone_work_temp.length > 0) {
        addEndDateData(zone_work_temp, timeZone);
      }


      let hasTaiConf = false;
      if(is_tai_conf && is_tai_conf.length > 0) {
        hasTaiConf = true;
        addEndDateData(is_tai_conf, timeZone);
      }

      if(tai_temp && tai_temp.length > 0) {
        addEndDateData(tai_temp, timeZone);
      }

      if(tank_temp && tank_temp.length > 0) {
        addEndDateData(tank_temp, timeZone);
      }


      // zone_work_temp: temperatura de trabajo
      const zoneWorkTemp = [];
      let hasZoneWorkTemp = false;
      // Si tenemos el parámetro procesamos el array para su representación
      if(zone_work_temp && zone_work_temp.length > 0) {
        hasZoneWorkTemp = true;
        for(let i = 0; i < zone_work_temp.length; i++) {
          // No incluímos predicciones futuras de work_temp
          if(zone_work_temp[i].dt > actualDateISOStr) break;

          if(hasTaiConf) {
            const isTaiConfActive = getValueBetween(zone_work_temp[i].dt, is_tai_conf)

            if(isTaiConfActive) continue;
          }

          const dt = zone_work_temp[i].dt;
          const value = zone_work_temp[i].value;
          const emptyValue = { celsius: null, fah: null}
          const isConnectedAtDate = getValueBetween(dt, isConnected);

          zoneWorkTemp.push({
            dt,
            value: isConnectedAtDate ? value : emptyValue, // Si está conectado en esa fecha pintamos la muestra, sino pintamos vacío
            connected: isConnectedAtDate,
          });

        }


      }

      if(tai_temp && tai_temp.length > 0) {

        const filledTaiTemp = fillData(receiveParams.tai_temp, CONSTANTS.GRAPHICS.PRECISION.minutes, timeZone);

        for(let i = 0; i < tai_temp.length; i++) {
          if(filledTaiTemp[i].dt > actualDateISOStr) break;

          const isTaiConfActive = getValueBetween(filledTaiTemp[i].dt, is_tai_conf)

          if(isTaiConfActive) {
            const dt = filledTaiTemp[i].dt;
            const value = filledTaiTemp[i].value;
            const emptyValue = { celsius: null, fah: null}
            const isConnectedAtDate = getValueBetween(dt, isConnected);

            zoneWorkTemp.push({
              dt,
              value: isConnectedAtDate ? value : emptyValue, // Si está conectado en esa fecha pintamos la muestra, sino pintamos vacío
              connected: isConnectedAtDate,
              isTaiTemp: true
            });
          }
        }
      }

      if(hasZoneWorkTemp && hasTaiConf) {
        // ordenamos por fecha las muestras. Al añadir a zoneWorkTemp muestras de dos arrays los valores pueden ir mezclados
        zoneWorkTemp.sort((x,y) => x.dt.localeCompare(y.dt))
      }

      response.zone_work_temp = fillData(zoneWorkTemp, CONSTANTS.GRAPHICS.PRECISION.minutes, timeZone);
      addEndDateData(response.zone_work_temp, timeZone);
      // weather: Datos del clima exterior (predicción)
      // Añadimos una muestra al final para su representación gráfica contínua.
      if(receiveParams.weather) {
        const weather = receiveParams.weather;


        weather.push({
          dt: moment(receiveParams.weather[receiveParams.weather.length - 1].dt).add(CONSTANTS.GRAPHICS.PRECISION.hours, 'hour').toISOString(),
          value: receiveParams.weather[receiveParams.weather.length - 1].value
        });

        response.weatherIcons = weather;
        response.weather = [];
        // response.weather = fillData(weather, CONSTANTS.GRAPHICS.PRECISION.minutes, timeZone); <- Si rellenamos weather cada PRECISION minutos
        for(let i = 0; i < weather.length; i++) {
          if(weather[i].dt > actualDateISOStr) break;

          response.weather.push({
            dt: weather[i].dt,
            value: weather[i].value
          }) // Los valores reales de weather serán de un valor cada hora
        }

        addEndDateData(response.weather, timeZone);


      }

      /**
       * ////// ACS MODE /////////////////////////////////////////////////////////////////////////////////////////////////////////
       * En caso de tener ACS recibimos tank_temp (en lugar de work_temp) pero se representa igual que work_temp en la gráfica
       * NOTA: En ACS tampoco tenemos modo. Para el cálculo del setpoint tendremos en cuenta sólo el valor de "setpoint"
       * ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
       */
      if(tank_temp && tank_temp.length > 0) {

        const filledTankTemp = fillData(tank_temp, CONSTANTS.GRAPHICS.PRECISION.minutes, timeZone);

        for(let i = 0; i < filledTankTemp.length; i++) {
          // No incluímos predicciones futuras de work_temp
          if(filledTankTemp[i].dt > actualDateISOStr) break;

          const dt = filledTankTemp[i].dt;
          const value = filledTankTemp[i].value;
          const emptyValue = { celsius: null, fah: null}
          const isConnectedAtDate = getValueBetween(dt, isConnected);

          zoneWorkTemp.push({
            dt,
            value: isConnectedAtDate ? value : emptyValue, // Si está conectado en esa fecha pintamos la muestra, sino pintamos vacío
            connected: isConnectedAtDate,
          });

        }

        response.zone_work_temp = zoneWorkTemp;


        if((!receiveParams.mode || receiveParams.mode.length === 0) &&
          receiveParams.setpoint && receiveParams.setpoint.length > 0) {

          addEndDateData(receiveParams.setpoint, timeZone);
          const setpoint = fillData(receiveParams.setpoint, CONSTANTS.GRAPHICS.PRECISION.minutes, timeZone);

          const output = []


          for(let i = 0; i < setpoint.length; i++) {
            // No incluímos predicciones futuras de work_temp
            if(setpoint[i].dt > actualDateISOStr) break;

            const dt = setpoint[i].dt;
            const value = setpoint[i].value;
            const emptyValue = { celsius: null, fah: null}
            const isConnectedAtDate = getValueBetween(dt, isConnected);

            output.push({
              dt,
              value: isConnectedAtDate ? value : emptyValue, // Si está conectado en esa fecha pintamos la muestra, sino pintamos vacío
              mode: getModeStringValue(CONSTANTS.MODES.HEATING),
              power: getValueBetween(dt, power),
              active: getValueBetween(dt, active),
            });

          }

          response.setpoint = output;

          addEndDateData(response.setpoint, timeZone);
        }
      }

      /**
       * ////// TAI MODE /////////////////////////////////////////////////////////////////////////////////////////////////////////
       * En caso de tener temperatura de impulsión recibimos tai_temp (en lugar de work_temp) pero se representa igual que work_temp en la gráfica
       * ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
       */
      if(tai_temp && tai_temp.length > 0 && tank_temp && tank_temp.length > 0) {

        const filledTankTemp = fillData(tank_temp, CONSTANTS.GRAPHICS.PRECISION.minutes, timeZone);

        for(let i = 0; i < tank_temp.length; i++) {
          // No incluímos predicciones futuras de work_temp
          if(filledTankTemp[i].dt > actualDateISOStr) break;

          const dt = filledTankTemp[i].dt;
          const value = filledTankTemp[i].value;
          const emptyValue = { celsius: null, fah: null}
          const isConnectedAtDate = getValueBetween(dt, isConnected);

          zoneWorkTemp.push({
            dt,
            value: isConnectedAtDate ? value : emptyValue, // Si está conectado en esa fecha pintamos la muestra, sino pintamos vacío
            connected: isConnectedAtDate,
          });

        }

        response.zone_work_temp = zoneWorkTemp;

      }

      if(receiveParams.mode) {
        let modes = receiveParams.mode;

        // Preparamos los arrays de setpoint que intervienen
        for(let i = 0; i < modes.length; i++) {
          addEndDateData(receiveParams[CONSTANTS.MODES.CLOUD_SP_KEYS[modes[i].value]], timeZone)
        }

        // Agregamos una muestra más al final del día con el último valor del modo registrado
        addEndDateData(modes, timeZone);

        modes = fillData(modes, CONSTANTS.GRAPHICS.PRECISION.minutes, timeZone)

        const setpoint = []
        const dbSetpoint = [];

        for(let i = 0; i < modes.length; i++) {
          // const modeStringValue = getModeStringValue(modes[i].value);

          let setpointModeData = null;

          if(modes[i].value === CONSTANTS.MODES.AUTO && double_sp && double_sp.length > 0) {

            let dbSetpointHeatData;
            let dbSetpointCoolData;

            const doubleSp = getValueBetween(modes[i].dt, double_sp);

            // Si tenemos doble setpoint y está activo tomamos los valores de setpoint de calor y de frío de sus respectivos arrays.
            if(doubleSp === true) {
              console.log("DOUBLE SP MODE");
              dbSetpointCoolData = receiveParams.setpoint_air_cool;
              dbSetpointHeatData = receiveParams.setpoint_air_heat;

              addEndDateData(dbSetpointCoolData, timeZone);
              addEndDateData(dbSetpointHeatData, timeZone);

              const setPointValueCool = getValueBetween(modes[i].dt, dbSetpointCoolData);
              const setPointValueHeat = getValueBetween(modes[i].dt, dbSetpointHeatData);


              setpoint.push({
                value: setPointValueCool,
                dt: modes[i].dt,
                mode: modes[i].value
              });


              dbSetpoint.push({
                value: setPointValueHeat,
                dt: modes[i].dt,
              });

            // Si tenemos el doble setpoint desactivado o no aplica, miramos si tenemos "auto_mode" para ver que modo aplica en cada caso,
            // pintamos el modo que esté activo (frío o calor), tomando el array de datos correspondiente a dicho modo
            } else if(auto_mode && auto_mode.length > 0){
              const autoModeActive = getValueBetween(modes[i].dt, auto_mode);
              dbSetpoint.push({
                value: {celius: null, fah: null},
                dt: modes[i].dt,
              });
              setpointModeData = receiveParams[CONSTANTS.MODES.CLOUD_SP_KEYS[autoModeActive]];

            // Si no tenemos información del auto mode y el doble setpoint no está activo, suponemos que es un sistema como modo auto en
            // single setpoint. Entonces tomamos los valores del arrai del 'setpoint_air_auto' y lo tratamos como cualquier otro modo
            } else {
              setpointModeData = receiveParams[CONSTANTS.MODES.CLOUD_SP_KEYS[modes[i].value]];
            }
          } else {
            setpointModeData = receiveParams[CONSTANTS.MODES.CLOUD_SP_KEYS[modes[i].value]];
          }
          // setpointModeData = receiveParams[CONSTANTS.MODES.CLOUD_SP_KEYS[modes[i].value]];
          //  Si no existe el setpoint del modo seleccionado o bien está vacío no hacemos nada más en esta iteración.

          if(modes[i].value === CONSTANTS.MODES.FAN && (!setpointModeData || setpointModeData.length === 0)) {

            const setPointValue = getValueBetween(modes[i].dt, response.zone_work_temp);

            setpoint.push({
              value: setPointValue,
              dt: modes[i].dt,
              mode: modes[i].value
            });

            continue;
          }


          if(!setpointModeData) continue;
          if(setpointModeData.length === 0) continue;


          let setPointValue = getValueBetween(modes[i].dt, setpointModeData);
          /**
           * Si tenemos modo "fan" y tenemos muestras en setpoint con valor null, pintamos la temperatura de trabajo en su lugar.
           */
          if(modes[i].value === CONSTANTS.MODES.FAN && (setPointValue?.celsius === null || setPointValue?.fah === null)) {
              setPointValue = getValueBetween(modes[i].dt, response.zone_work_temp);
          }


          setpoint.push({
            value: setPointValue,
            dt: modes[i].dt,
            mode: modes[i].value
          })
        }




        // Si no hay dato de setpoint en la fecha final, lo forzamos con el último valor de setpoint registrado para pintar la gráfica

        // addEndDateData(setpoint, timeZone);

        // setpoint = fillData(setpoint, CONSTANTS.GRAPHICS.PRECISION.minutes, timeZone);

        const output = [];
        const emptyValue = {celsius: null, fah: null}

        for(let i = 0; i < setpoint.length; i++) {
          const dt = setpoint[i].dt;
          const mode = getModeStringValue(setpoint[i].mode);

          let autoMode = {}
          let value = {}

          if(mode === 'auto' && auto_mode && auto_mode.length > 0) {
            autoMode = {
              auto_mode: getValueBetween(dt, auto_mode)
            }
          }
          /**
           * Si tenemos modo "fan" y no tenemos setpoint, pintamos la temperatura de trabajo en su lugar.
           */
          if(mode === 'fan' && setpoint.length === 0) {
            value = getValueBetween(dt, isConnected) ? getValueBetween(dt, response.zone_work_temp) : emptyValue;
          } else {
            value = getValueBetween(dt, isConnected) ? setpoint[i]?.value : emptyValue;
          }

          output.push({
            dt,
            mode,
            value,
            power: getValueBetween(dt, power),
            active: getValueBetween(dt, active),
            ...autoMode
          })
        }

        // Si tenemos datos en doble setpoint agregamos la última muestra y agregamos a la respueta
        if(dbSetpoint && dbSetpoint.length > 0) {
          addEndDateData(dbSetpoint, timeZone);

          response.dbSetpoint = []
          for(let i = 0; i < dbSetpoint.length; i++) {
            const dt = dbSetpoint[i].dt;
            const value = getValueBetween(dt, isConnected) ? dbSetpoint[i].value : emptyValue;

            response.dbSetpoint.push({
              dt: dbSetpoint[i].dt,
              value
            })
          }

          console.log("dbSetpoint", dbSetpoint);

        }



        // Rellenamos en setpoint las muestras producidas por los eventos puntuales
        // // MODO -------------------------------
        const modesAvailable = []; // Control de los modos que ya se hayan recorrido para cargar los valores de setpoint;

        for(let i = 0; i < receiveParams.mode.length; i++) {
          const dt = `${receiveParams.mode[i]?.dt.split('.')[0].slice(0, -2)}00.000Z`;
          const connect = getValueBetween(dt, isConnected);

          output.push({
            dt,
            value: connect ? getValueBetween(receiveParams.mode[i].dt, setpoint) : emptyValue,
            mode: getModeStringValue(receiveParams.mode[i].value),
            power: getValueBetween(dt, power),
            active: getValueBetween(dt, active)
          })
          // rellenamos la muestra
          addPreviusValueData(dt, response.zone_work_temp);
          // addPreviusValueData(dt, response.weather);

          // SETPOINT --------------------------------
          let setpointModeData = null;


          if(modes[i].value === CONSTANTS.MODES.AUTO && double_sp && double_sp.length > 0) {

            // Setpoint de frio (AUTO)
            for(let j = 0; j < receiveParams.setpoint_air_cool; j++) {
              const sp_cool_dt = `${receiveParams.setpoint_air_cool[j]?.dt.split('.')[0].slice(0, -2)}00.000Z`;
              const doubleSp = getValueBetween(sp_cool_dt, double_sp);

              if(doubleSp === true) {

                const data = {
                  dt: sp_cool_dt,
                  value: getValueBetween(sp_cool_dt, isConnected) ? receiveParams.setpoint_air_cool[j]?.value : emptyValue,
                  mode: getModeStringValue(getValueBetween(sp_cool_dt, modes)),
                  power: getValueBetween(sp_cool_dt, power),
                  active: getValueBetween(sp_cool_dt, active)
                }

                output.push(data)

                addPreviusValueData(sp_cool_dt, response.dbSetpoint);



              }
            }

            // Setpoint de calor (AUTO)
            for(let j = 0; j < receiveParams.setpoint_air_heat; j++) {
              const sp_heat_dt = `${receiveParams.setpoint_air_heat[j]?.dt.split('.')[0].slice(0, -2)}00.000Z`;
              const doubleSp = getValueBetween(sp_heat_dt, double_sp);

              if(doubleSp === true) {

                const data = {
                  dt: sp_heat_dt,
                  value: getValueBetween(sp_heat_dt, isConnected) ? receiveParams.setpoint_air_heat[j]?.value : emptyValue,
                  mode: getModeStringValue(getValueBetween(sp_heat_dt, modes)),
                  power: getValueBetween(sp_heat_dt, power),
                  active: getValueBetween(sp_heat_dt, active)
                }

                response.dbSetpoint.push(data)

                addPreviusValueData(sp_heat_dt, output);

                response.dbSetpoint.sort((x,y) => x.dt.localeCompare(y.dt));

              }
            }

          } else {
            const keyMode = CONSTANTS.MODES.CLOUD_SP_KEYS[modes[i].value];


            if(modesAvailable.indexOf(keyMode) === -1) {
              setpointModeData = receiveParams[keyMode];
              modesAvailable.push(keyMode);

              // Incluímos los cambios de setpoint del modo implicado
              for(let j = 0; j < setpointModeData.length; j++) {
                const sp_mode_dt = `${setpointModeData[j].dt?.split('.')[0].slice(0, -2)}00.000Z`;

                output.push({
                  dt: sp_mode_dt,
                  value: getValueBetween(sp_mode_dt, isConnected) ? setpointModeData[j].value : emptyValue,
                  mode: getModeStringValue(getValueBetween(sp_mode_dt, modes)),
                  power: getValueBetween(sp_mode_dt, power),
                  active: getValueBetween(sp_mode_dt, active)
                })

                addPreviusValueData(sp_mode_dt, response.zone_work_temp);
                // addPreviusValueData(sp_mode_dt, response.weather);
              }

            }

          }

        }
        // // // POWER -------------------------------
        for(let i = 0; i < receiveParams.power.length; i++) {

          const dt = `${receiveParams.power[i].dt?.split('.')[0].slice(0,-2)}00.000Z`;

          output.push({
            dt,
            value: getValueBetween(dt, isConnected) ? getValueBetween(dt, setpoint) : emptyValue,
            mode: getModeStringValue(getValueBetween(dt, modes)),
            power: receiveParams.power[i].value,
            active: getValueBetween(dt, active)
          })

          const doubleSp = getValueBetween(dt, double_sp);

          if(doubleSp === true) {
            addPreviusValueData(dt, response.dbSetpoint);
          }

          addPreviusValueData(dt, response.zone_work_temp);
          // addPreviusValueData(dt, response.weather);
        }

        // SETPOINT -----------------------------------

        // ACTIVE -------------------------------
        for(let i = 0; i < active.length; i++) {

          const dt = active[i].dt;

          output.push({
            dt,
            value: getValueBetween(dt, isConnected) ? getValueBetween(dt, setpoint) : emptyValue,
            mode: getModeStringValue(getValueBetween(dt, modes)),
            power: getValueBetween(dt, power),
            active: active[i].value
          });

          const doubleSp = getValueBetween(dt, double_sp);

          if(doubleSp === true) {
            addPreviusValueData(dt, response.dbSetpoint);
          }

          addPreviusValueData(dt, response.zone_work_temp);
          // addPreviusValueData(dt, response.weather);
        }
        // ------------------------------------------------
        output.sort((x,y) => x.dt.localeCompare(y.dt));

        response.setpoint = output;

        // addEndDateData(response.setpoint, timeZone);

      }


      response.power = power;
      response.active = active;
      response.isConnected = isConnected;

    }

    if(receiveData.prev !== undefined) {
      response.prev = receiveData.prev;
    }

    if(receiveData.next !== undefined) {
      response.next = receiveData.next;
    }

    return response;
  },

  getGraphAqData: (receiveData, timeZone) => {
    const params = receiveData.params;
    const response = {}

    if(params) {
      let isConnected = [];
      // const aqQuality = [];

      // Preparamos endData en los parámetros de medición
      // ------------------------------------------------
      if(params?.aq_quality?.length > 0) {
        addEndDateData(params.aq_quality, timeZone);
      }

      if(params?.aq_score?.length > 0) {
        const aq_score = params.aq_score;
        addEndDateData(aq_score, timeZone);
        response.aq_score = fillData(aq_score, CONSTANTS.GRAPHICS.PRECISION.minutes, timeZone);
      }


      if(params.aqpm2_5?.length > 0) {
        addEndDateData(params.aqpm2_5, timeZone);
      }

      if(params.aq_pm2_5_quality?.length > 0) {
        addEndDateData(params.aq_pm2_5_quality, timeZone);
      }

      if(params.aqpm10?.length > 0) {
        addEndDateData(params.aqpm10, timeZone);
      }

      if(params.aq_pm10_quality?.length > 0) {
        addEndDateData(params.aq_pm10_quality, timeZone);
      }

      if(params.aq_co2?.length > 0) {
        addEndDateData(params.aq_co2, timeZone);
      }

      if(params?.aq_co2_quality?.length > 0) {
        addEndDateData(params.aq_co2_quality, timeZone);
      }

      if(params.aq_tvoc?.length > 0) {
        addEndDateData(params.aq_tvoc, timeZone);
      }

      if(params.aq_tvoc_quality?.length > 0) {
        addEndDateData(params.aq_tvoc_quality, timeZone);
      }

      if(params.aq_hum_quality?.length > 0) {
        addEndDateData(params.aq_hum_quality, timeZone);
      }

      if(params.humidity?.length > 0) {
        addEndDateData(params.humidity, timeZone);
      }


      // Preparamos el resto de parámetros y datos de estado
      // ---------------------------------------------------

      if(params?.isConnected?.length > 0) {
        isConnected = smoothConnectionData(params.isConnected, 60 * 30);
        addEndDateData(isConnected,timeZone);
        response.isConnected = isConnected
      }

      if(params?.aq_vent_active?.length > 0) {
        const aq_vent_active = params.aq_vent_active;
        const actualDate =  moment().tz(timeZone).toISOString();
        addEndDateData(aq_vent_active, timeZone);
        // Pongo a false valores cuando no está conectado el webserver
        aq_vent_active.forEach(aqVentActive => {
          const isConnectedAtDate = getValueBetween(aqVentActive.dt, isConnected);
          if (!isConnectedAtDate) aqVentActive.value = false
          // Si la fecha es mayor o igual a la actual, ponemos a false el valor no podemos tener información futura
          if(`${aqVentActive.dt.split('.')[0].slice(0,-2) }00.000Z` >= `${actualDate.split('.')[0].slice(0,-2)}00.000Z`) aqVentActive.value = false
        })
        response.aq_vent_active = aq_vent_active;
      }

      if(params?.aq_active?.length > 0) {
        const aq_active = params.aq_active;
        addEndDateData(aq_active, timeZone);
        // Pongo a false valores cuando no está conectado el webserver
        aq_active.forEach(aqActive => {
          const isConnectedAtDate = getValueBetween(aqActive.dt, isConnected);
          if (!isConnectedAtDate) aqActive.value = false
        })
        response.aq_active = aq_active;
      }

      if(params?.aq_quality?.length > 0) {
        const aqQuality = fillData(params.aq_quality, CONSTANTS.GRAPHICS.PRECISION.minutes, timeZone);

        response.aq_quality = aqQuality;
        // Si no hay aq_score, la información de "summary" la extraemos de aquí
        if(!params.aq_score || (params.aq_score && params.aq_score.length === 0)){
          let max = {
            dt: null,
            value: CONSTANTS.AQ_QUALITY_VALUES.bad,
            aq_quality: CONSTANTS.AQ_QUALITY_VALUES.bad
          };
          let min = {
            dt: null,
            value: CONSTANTS.AQ_QUALITY_VALUES.good,
            aq_quality: CONSTANTS.AQ_QUALITY_VALUES.good
          };
          let ctnBad = 0;
          let ctnRegular = 0;
          let ctnGood = 0;

          const mapValues = {
            bad: 0,
            regular: 1,
            good: 2
          }

          response.aq_quality = [];

          for(let i = aqQuality.length - 1; i >= 0; i--) {
            const sample = getValueBetween(aqQuality[i].dt, isConnected) ? aqQuality[i] : {dt: aqQuality[i].dt, value: null}
            response.aq_quality.push(sample);

            if(sample.value !== null && mapValues[sample.value] <= mapValues[min.value] ) {
              min = {...sample, aq_quality: sample.value}
            }
            if(mapValues[sample.value] >= mapValues[max.value]) {
              max = {...sample, aq_quality: sample.value}
            }

            switch (sample.value) {
              case CONSTANTS.AQ_QUALITY_VALUES.bad:
                ctnBad++;
                break;
              case CONSTANTS.AQ_QUALITY_VALUES.regular:
                ctnRegular++;
                break;
              case CONSTANTS.AQ_QUALITY_VALUES.good:
                ctnGood++;
                break;
              default:
            }

          }

          let average;

          if(ctnGood >= ctnRegular && ctnGood >= ctnBad) {
            average = CONSTANTS.AQ_QUALITY_VALUES.good
          } else if(ctnRegular >= ctnGood && ctnRegular >= ctnBad) {
            average = CONSTANTS.AQ_QUALITY_VALUES.regular
          } else if(ctnBad >= ctnGood && ctnBad >= ctnRegular) {
            average = CONSTANTS.AQ_QUALITY_VALUES.bad
          }

          response.aq_score_summary = {
            min,
            max,
            average,
            aria_label: i18n.global.t('accesibility.aq.cai'),
            aria_unit: ''
          }
        } else {
          // Si hay aq_score rellenamos el aq_quality de forma normal (sin tenerlo en cuanta para aq_score_summary)
          response.aq_quality = [];

          for(let i = aqQuality.length - 1; i >= 0; i--) {
            const sample = getValueBetween(aqQuality[i].dt, isConnected) ? aqQuality[i] : {dt: aqQuality[i].dt, value: null};

            response.aq_quality.push(sample);
          }
        }
      }

      /**
       * Air Quality (EXTERIOR) - Proviene del api de clima externo, nos dá el índice de calidad de aire en
       * formato {dt: string, value: { aqi: number, components: {co: number, no: number, o2: number, etc... } }}
       */
      if(params.aq) {
        const aq = [];

        for(let i = 0; i < params.aq.length ; i++) {
          if(params.aq[i]?.value?.aqi && params.aq[i]?.dt) {
            aq.push({
              dt: params.aq[i].dt,
              value: params.aq[i].value?.aqi
            });
          }

        }

        // Si tengo menos de 24 muestras (día actual)
        if(params.aq.length < 24) {
          aq.push({
            dt: moment(params.aq[params.aq.length - 1].dt).add(1, 'hour').toISOString(),
            value: 0
          })
        }

        // Agregamos una muestra más al final del día con el último valor del modo registrado
        // const endDate = moment(params.aq[0].dt).add(24, 'hour').toISOString();

        response.aq = aq;

      }

      // Volcamos los parámetros de medición
      if(params.aq_co2) {

        const aq_co2 = fillData(params.aq_co2, CONSTANTS.GRAPHICS.PRECISION.minutes, timeZone);

        let min = {dt: null, value: Infinity};
        let max = {dt: null, value: -Infinity};
        let sum = 0;


        response.aq_co2 = [];

        if(params?.aq_co2_quality?.length > 0) {
          // response.aq_co2_quality = fillData(params.aq_co2_quality, CONSTANTS.GRAPHICS.PRECISION.minutes, timeZone);
          response.aq_co2_quality = params.aq_co2_quality;

          // Agregamos las muestras de los flancos de aq_xxx_quality a su correspondiente muestra de aq_xxx
          response.aq_co2_quality.forEach(aqQuality => {

            const dt = `${aqQuality?.dt?.split('.')[0].slice(0,-2)}00.000Z`; // Redondeamos a la hora

            const aqQualityValue = aqQuality.value;

            // console.log("AGREGANDO MUESTRA", dt, aqQualityValue, getValueBetween(dt, params.aq_co2));

            response.aq_co2.push({
              x: dt,
              y: getValueBetween(dt, params.aq_co2),
              aq_co2_quality: aqQualityValue
            });
          });
        }

        for(let i = 0; i < aq_co2.length; i++) {
          const dt = aq_co2[i].dt;

          // if(params.aq_co2[i].value === null ) continue;

          if(aq_co2[i].value !== null && aq_co2[i].value <= min.value) {
            min = aq_co2[i]
          }

          if(aq_co2[i].value !== null && aq_co2[i].value >= max.value) {
            max = aq_co2[i];
          }

          sum += aq_co2[i].value;

          // AQ Quality value
          if(params?.aq_co2_quality?.length > 0) {
            const aqQualityValue =  getValueBetween(dt, response.aq_co2_quality) || undefined

            response.aq_co2.push({
              x: dt,
              y: getValueBetween(dt, isConnected) ? aq_co2[i].value : null,
              aq_co2_quality:  aqQualityValue
            })
          } else {
            response.aq_co2.push({
              x: dt,
              y: getValueBetween(dt, isConnected) ? aq_co2[i].value : null
            })
          }

        }

        response.aq_co_2_summary = {
          min,
          max,
          average: sum / aq_co2.filter(item => item.value !== null).length,
          unit: 'ppm',
          aria_unit: i18n.global.t('accesibility.aq_units.ppm'),
          aria_label: i18n.global.t('accesibility.aq.co2')
        }

        // Ordenamos las muestras por fecha
        response.aq_co2.sort((a,b) => a.x.localeCompare(b.x))
      }

      if(params.aqpm2_5) {

        let min = {dt: null, value: Infinity};
        let max = {dt: null, value: -Infinity};
        let sum = 0;

        const aqpm2_5 = fillData(params.aqpm2_5, CONSTANTS.GRAPHICS.PRECISION.minutes, timeZone);

        response.aqpm2_5 = [];

        if(params?.aq_pm2_5_quality?.length > 0) {
          response.aq_pm2_5_quality = params.aq_pm2_5_quality;

          // Agregamos las muestras de los flancos de aq_xxx_quality a su correspondiente muestra de aq_xxx
          response.aq_pm2_5_quality.forEach(aqQuality => {

            const dt = `${aqQuality?.dt?.split('.')[0].slice(0,-2)}00.000Z`; // Redondeamos a la hora

            const aqQualityValue = aqQuality.value;

            response.aqpm2_5.push({
              x: dt,
              y: getValueBetween(dt, params.aqpm2_5),
              aq_pm2_5_quality: aqQualityValue
            });
          });
        }

        for(let i = 0; i < aqpm2_5.length; i++) {
          const dt = aqpm2_5[i].dt;

          // if(aqpm2_5[i].value === null ) continue;

          if(aqpm2_5[i].value !== null && aqpm2_5[i].value <= min.value) {
            min = aqpm2_5[i]
          }

          if(aqpm2_5[i].value !== null && aqpm2_5[i].value >= max.value) {
            max = aqpm2_5[i];
          }

          sum += aqpm2_5[i].value;

          // AQ Quality value
          if(params?.aq_pm2_5_quality?.length > 0) {

            const aqQualityValue =  getValueBetween(dt, response.aq_pm2_5_quality) || undefined

            response.aqpm2_5.push({
              x: dt,
              y: getValueBetween(dt, isConnected) ? aqpm2_5[i].value : null,
              aq_pm2_5_quality:  aqQualityValue
            })
          } else {
            response.aqpm2_5.push({
              x: dt,
              y: getValueBetween(dt, isConnected) ? aqpm2_5[i].value : null
            })
          }


        }

        response.aqpm2_5_summary = {
          min,
          max,
          average: sum / aqpm2_5.filter(item => item.value !== null).length,
          unit: 'μg/m<sup>3</sup>',
          aria_unit: i18n.global.t('accesibility.aq_units.microg_m3'),
          aria_label: i18n.global.t('accesibility.aq.pm2_5')
        }

        // Ordenamos las muestras por fecha
        response.aqpm2_5.sort((a,b) => a.x.localeCompare(b.x));
      }

      if(params.aqpm10) {

        const aqpm10 = fillData(params.aqpm10, CONSTANTS.GRAPHICS.PRECISION.minutes, timeZone);

        let min = {dt: null, value: Infinity};
        let max = {dt: null, value: -Infinity};
        let sum = 0;

        response.aqpm10 = [];

        if(params?.aq_pm10_quality?.length > 0) {
          response.aq_pm10_quality = params.aq_pm10_quality;

          // Agregamos las muestras de los flancos de aq_xxx_quality a su correspondiente muestra de aq_xxx
          response.aq_pm10_quality.forEach(aqQuality => {

            const dt = `${aqQuality?.dt?.split('.')[0].slice(0,-2)}00.000Z`; // Redondeamos a la hora

            const aqQualityValue = aqQuality.value;

            response.aqpm10.push({
              x: dt,
              y: getValueBetween(dt, params.aqpm10),
              aq_pm10_quality: aqQualityValue
            });
          });
        }

        for(let i = 0; i < aqpm10.length; i++) {
          // if(aqpm10[i].value === null ) continue;

          const dt = aqpm10[i].dt;

          if(aqpm10[i].value !== null && aqpm10[i].value <= min.value) {
            min = aqpm10[i]
          }

          if(aqpm10[i].value !== null && aqpm10[i].value >= max.value) {
            max = aqpm10[i];
          }

          sum += aqpm10[i].value;

          if(params?.aq_pm10_quality?.length > 0) {
            const aqQualityValue =  getValueBetween(dt, response.aq_pm10_quality) || undefined

            response.aqpm10.push({
              x: dt,
              y: getValueBetween(dt, isConnected) ? aqpm10[i].value : null,
              aq_pm10_quality: aqQualityValue
            });
          } else {
            response.aqpm10.push({
              x: dt,
              y: getValueBetween(dt, isConnected) ? aqpm10[i].value : null
            });
          }

        }

        response.aqpm10_summary = {
          min,
          max,
          average: sum / aqpm10.filter(item => item.value !== null).length,
          unit: 'μg/m<sup>3</sup>',
          aria_unit: i18n.global.t('accesibility.aq_units.microg_m3'),
          aria_label: i18n.global.t('accesibility.aq.pm10')
        }

        // Ordenamos las muestras por fecha
        response.aqpm10.sort((a,b) => a.x.localeCompare(b.x));
      }

      if(params.aq_tvoc) {

        const aq_tvoc = fillData(params.aq_tvoc, CONSTANTS.GRAPHICS.PRECISION.minutes, timeZone);

        let min = {dt: null, value: Infinity};
        let max = {dt: null, value: -Infinity};
        let sum = 0;

        response.aq_tvoc = [];

        if(params?.aq_tvoc_quality?.length > 0) {
          response.aq_tvoc_quality = params.aq_tvoc_quality, CONSTANTS.GRAPHICS.PRECISION.minutes, timeZone;

          // Agregamos las muestras de los flancos de aq_xxx_quality a su correspondiente muestra de aq_xxx
          response.aq_tvoc_quality.forEach(aqQuality => {

            const dt = `${aqQuality?.dt?.split('.')[0].slice(0,-2)}00.000Z`; // Redondeamos a la hora

            const aqQualityValue = aqQuality.value;

            response.aq_tvoc.push({
              x: dt,
              y: getValueBetween(dt, params.aq_tvoc),
              aq_tvoc_quality: aqQualityValue
            });
          });
        }

        for(let i = 0; i < aq_tvoc.length; i++) {
          // if(aq_tvoc[i].value === null ) continue;
          const dt = aq_tvoc[i].dt;

          if(aq_tvoc[i].value !== null && aq_tvoc[i].value <= min.value) {
            min = aq_tvoc[i]
          }

          if(aq_tvoc[i].value !== null && aq_tvoc[i].value >= max.value) {
            max = aq_tvoc[i];
          }

          sum += aq_tvoc[i].value;

          // AQ Quality value
          if(params?.aq_tvoc_quality?.length > 0) {
            const aqQualityValue =  getValueBetween(dt, response.aq_tvoc_quality) || undefined

            response.aq_tvoc.push({
              x: dt,
              y: getValueBetween(dt, isConnected) ? aq_tvoc[i].value : null,
              aq_tvoc_quality:  aqQualityValue
            })
          } else {
            response.aq_tvoc.push({
              x: dt,
              y: getValueBetween(dt, isConnected) ? aq_tvoc[i].value : null
            })
          }

        }

        response.aq_tvoc_summary = {
          min,
          max,
          average: sum / aq_tvoc.filter(item => item.value !== null).length,
          unit: 'ppb',
          aria_unit: i18n.global.t('accesibility.aq_units.ppb'),
          aria_label: i18n.global.t('accesibility.aq.tvoc')
        }

        // Ordenamos las muestras por fecha
        response.aq_tvoc.sort((a,b) => a.x.localeCompare(b.x));
      }

      if(params.humidity) {

        const humidity = fillData(params.humidity, CONSTANTS.GRAPHICS.PRECISION.minutes, timeZone);

        let min = {dt: null, value: Infinity};
        let max = {dt: null, value: -Infinity};
        let sum = 0;

        response.humidity = [];

        if(params?.aq_hum_quality?.length > 0) {
          response.aq_hum_quality = params.aq_hum_quality;

          // Agregamos las muestras de los flancos de aq_xxx_quality a su correspondiente muestra de aq_xxx
          response.aq_hum_quality.forEach(aqQuality => {

            const dt = `${aqQuality?.dt?.split('.')[0].slice(0,-2)}00.000Z`; // Redondeamos a la hora

            const aqQualityValue = aqQuality.value;

            response.humidity.push({
              x: dt,
              y: getValueBetween(dt, params.humidity),
              aq_hum_quality: aqQualityValue
            });
          });
        }

        for(let i = 0; i < humidity.length; i++) {
          // if(humidity[i].value === null ) continue;
          const dt = humidity[i].dt;

          if(humidity[i].value !== null && humidity[i].value <= min.value) {
            min = humidity[i]
          }

          if(humidity[i].value !== null && humidity[i].value >= max.value) {
            max = humidity[i];
          }

          sum += humidity[i].value;

          // AQ Quality value
          if(params?.aq_hum_quality?.length > 0) {
            const aqQualityValue =  getValueBetween(dt, response.aq_hum_quality) || undefined

            response.humidity.push({
              x: dt,
              y: getValueBetween(dt, isConnected) ? humidity[i].value : null,
              aq_hum_quality:  aqQualityValue
            })
          } else {
            response.humidity.push({
              x: dt,
              y: getValueBetween(dt, isConnected) ? humidity[i].value : null
            })
          }

        }

        response.aq_humidity_summary = {
          min,
          max,
          average: sum / humidity.filter(item => item.value !== null).length,
          unit: '%',
          aria_unit: '%',
          aria_label: '%'
        }

        // Ordenamos las muestras por fecha
        response.humidity.sort((a,b) => a.x.localeCompare(b.x));
      }

      // CAI (score)
      if(params.aq_score && params.aq_score.length > 0) {

        let min = {dt: null, value: Infinity};
        let max = {dt: null, value: -Infinity};
        let sum = 0;
        let aqQualityValue;

        const aq_score = fillData(params.aq_score, CONSTANTS.GRAPHICS.PRECISION.minutes, timeZone);

        const aqQuality = params.aq_quality;

        addEndDateData(aqQuality, timeZone);

        for(let i = 0; i < aq_score.length; i++) {

          const dt = aq_score[i].dt;

          aqQualityValue = response.aq_quality ? getValueBetween(dt, aqQuality) : undefined
          // aqQualityValue = response.aq_quality ? response.aq_quality[i]?.value : undefined

          response.aq_score.push({
            x: dt,
            y: getValueBetween(dt, isConnected) ? aq_score[i].value : null,
            aq_quality:  aqQualityValue
          })

          if(aq_score[i].value === null ) continue;

          if(aq_score[i].value <= min.value) {
            min = aq_score[i]
            min.aq_quality = aqQualityValue;
          }

          if(aq_score[i].value >= max.value) {
            max = aq_score[i];
            max.aq_quality = aqQualityValue;
          }

          sum += aq_score[i].value;

        }

        response.aq_score_summary = {
          min,
          max,
          average: sum / aq_score.filter(item => item.value !== null).length,
          aria_label: i18n.global.t('accesibility.aq.cai'),
          aria_unit: ''
        }
      }


    }

    if(receiveData.prev !== undefined) {
      response.prev = receiveData.prev;
    }

    if(receiveData.next !== undefined) {
      response.next = receiveData.next;
    }

    return response
  },

  getMetricsData: responseData => {
    console.log("METRICS DATA", responseData);

    const response = responseData

    return response;
  },

  getParsedNotifications: (notifications) => {
    const response = [];

    if(notifications && notifications.length > 0) {
      for(let i = 0; i < notifications.length; i++) {
        const data = notifications[i]?.data;
        // Las notificaciones de tipo "warning o error" incluyen una ID separada por "::"
        const splitedType = typeof notifications[i]?.type === 'string' ? notifications[i].type.split('::') : null;
        const typeName = (splitedType !== null) ? splitedType[0] : null; // Tipo de notificación
        const warningId = (splitedType !== null && splitedType.length > 1) ? splitedType[1] : null; // ID del warning

        response.push(
          {
            id: notifications[i]._id,
            group: notifications[i].group,
            type: typeName,
            warningId,
            title: data?.title,
            body: data?.body,
            read: data?.read,
            label: data?.label,
            render_buttons: data?.render_buttons || [],
            render_blocks: data?.render_blocks || [],
            push_click_actions: data?.push_click_actions || [],
            dt: data?.dt,
          }
        )
      }
    }

    return response;
  }
}

export default cloud2web;
